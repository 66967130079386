import { MultiValue, SingleValue, Props as SelectProps } from 'react-select';

/**
 * UI Selectors
 */
export type ReactSelectOption = {
  hiddenValue?: string | number;
  value: string | number | null;
  label: string | JSX.Element;
} | null;

type BaseSelectProps = Omit<SelectProps<ReactSelectOption>, 'onChange' | 'value' | 'defaultValue'>;

export interface SingleSelectorProps extends BaseSelectProps {
  value: string | number | null;
  defaultValue?: string | number;
  onChange: (newValue: string | number) => void;
  isMulti?: false;
  isInvalid?: boolean;
  placeholder?: string;
}

export interface MultiSelectorProps extends BaseSelectProps {
  value: Array<string | number> | null;
  defaultValue?: Array<string | number>;
  onChange: (newValue: Array<string | number>) => void;
  isMulti: true;
  isInvalid?: boolean;
  placeholder?: string;
}

export type SelectorProps = SingleSelectorProps | MultiSelectorProps;
export type SelectorOnChange = MultiValue<ReactSelectOption> | SingleValue<ReactSelectOption>;
export const isMultiSelector = (props: SelectorProps): props is MultiSelectorProps => {
  return !!props.isMulti;
};
