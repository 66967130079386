import { validateNonEmptyObject, validateObjectKey, validateString } from 'common';

interface Envs {
  appVersion: string;
  appName: string;
  release: string;
  apiBaseURL: string;
  firmwareBaseURL: string;
  env: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  identityPoolId: string;

  // mqtt
  mqttEndpoint: string;

  // sentry
  sentryDsn: string;
}

function validate(envs: unknown): asserts envs is Envs {
  validateNonEmptyObject(envs);

  validateObjectKey(envs, 'appVersion');
  validateObjectKey(envs, 'appName');
  validateObjectKey(envs, 'release');
  validateObjectKey(envs, 'apiBaseURL');
  validateObjectKey(envs, 'firmwareBaseURL');
  validateObjectKey(envs, 'env');
  validateObjectKey(envs, 'region');
  validateObjectKey(envs, 'userPoolId');
  validateObjectKey(envs, 'userPoolWebClientId');
  validateObjectKey(envs, 'identityPoolId');
  validateObjectKey(envs, 'mqttEndpoint');
  validateObjectKey(envs, 'sentryDsn');

  validateString(envs.appVersion);
  validateString(envs.appName);
  validateString(envs.release);
  validateString(envs.firmwareBaseURL);
  validateString(envs.apiBaseURL);
  validateString(envs.env);
  validateString(envs.region);
  validateString(envs.userPoolId);
  validateString(envs.userPoolWebClientId);
  validateString(envs.identityPoolId);
  validateString(envs.mqttEndpoint);
  validateString(envs.sentryDsn);
}

// envs from local .env file to be use local env
export const getDevEnvs = () => {
  const envs = import.meta.env;

  const formattedEnvs: Envs = {
    appVersion: envs.VITE_APP_VERSION as string,
    appName: envs.VITE_APP_NAME as string,
    release: envs.VITE_RELEASE as string,
    firmwareBaseURL: envs.VITE_FIRMWARE_BASE_URL as string,
    apiBaseURL: envs.VITE_API_BASE_URL as string,
    env: envs.VITE_ENV as string,
    region: envs.VITE_REGION as string,
    userPoolId: envs.VITE_COGNITO_USER_POOL_ID as string,
    userPoolWebClientId: envs.VITE_COGNITO_CLIENT_APP_ID as string,
    identityPoolId: envs.VITE_IDENTITY_POOL_ID as string,
    mqttEndpoint: envs.VITE_MQTT_ENDPOINT as string,
    sentryDsn: envs.VITE_SENTRY_DSN as string,
  };

  return formattedEnvs;
};

// envs served by backend for non-local env
export const getEnvs = (): Envs => {
  if ('envs' in window) {
    const { envs } = window;
    validate(envs);

    return envs;
  } else {
    const devEnvs = getDevEnvs() as unknown;
    validate(devEnvs);

    return devEnvs;
  }
};
