import { API, APIInputs, APIOutputs } from '../../api/trpc';

export type CreateSubscriptionInput = APIInputs['subscriptions']['createCheckoutUrl'];
export type CreateSubscriptionOutput = APIOutputs['subscriptions']['createCheckoutUrl'];

export const useCreateSubscription = () => {
  const onSuccess = (checkout: CreateSubscriptionOutput) => {
    window.location.href = checkout.url;
  };

  const { mutate: createSubscriptionToPlan, isLoading, error } = API.subscriptions.createCheckoutUrl.useMutation({ onSuccess });

  const onSubmit = (planId: number) => {
    createSubscriptionToPlan({ plan: planId });
  };

  return { onSubmit, isLoading, error };
};
