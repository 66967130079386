import { useColorMode, useTheme } from '@chakra-ui/react';
import { Theme } from 'react-select';
import { AppTheme } from '../../../../theme';

/**
 * Parse react select theme into a chakra based theme
 * @returns {() => Theme} parser
 */
export const useChakraThemeParser = () => {
  const { colorMode } = useColorMode();
  const { colors } = useTheme<AppTheme>();

  const newColors =
    colorMode === 'light'
      ? {
          primary: colors.brand['400'],
          primary25: colors.brand['100'],
          primary50: colors.brand['200'],
          primary75: colors.brand['300'],
          danger: colors.red['600'],
          dangerLight: colors.red['300'],
          neutral0: colors.gray['100'],
          neutral5: 'transparent',
          neutral10: colors.brand['300'],
          neutral20: colors.light['50'],
          neutral30: colors.brand['300'],
          neutral40: colors.dark['50'],
          neutral50: colors.dark['50'],
          neutral60: colors.light['50'],
          neutral70: colors.light['200'],
          neutral80: colors.dark['500'],
          neutral90: colors.dark['900'],
        }
      : {
          primary: colors.brand['100'],
          primary25: colors.brand['900'],
          primary50: colors.brand['800'],
          primary75: colors.brand['700'],
          danger: colors.red['800'],
          dangerLight: colors.red['500'],
          neutral0: colors.dark['400'],
          neutral5: 'transparent',
          neutral10: colors.brand['400'],
          neutral20: colors.dark['50'],
          neutral30: colors.brand['400'],
          neutral40: colors.light['50'],
          neutral50: colors.light['50'],
          neutral60: colors.dark['50'],
          neutral70: colors.dark['200'],
          neutral80: colors.white['50'],
          neutral90: colors.light['900'],
        };

  const parser = (theme: Theme) => {
    return {
      ...theme,
      colors: {
        ...newColors,
      },
    };
  };

  return parser;
};
