import React from 'react';
import { Alert, AlertProps, AlertIcon, AlertDescription, CloseButton, Fade } from '@chakra-ui/react';
import { MessageDescriptor } from 'react-intl';
import { TranslatedMessage } from '../../i18n/TranslatedMessage';
import { APIErrorFormatter, defaultFormatter, errorMessages } from './formatter';
import { TRPCClientErrorLike } from '@trpc/client';
import { API } from '../../api/trpc';

interface Props extends AlertProps {
  error: MessageDescriptor;
  onClose?: () => void;
}

export const ErrorViewer: React.FC<Props> = (props) => {
  const { error, onClose, ...otherProps } = props;

  return (
    <Fade in>
      <Alert status="error" variant="top-accent" my="2" maxW={'100%'} {...otherProps}>
        <AlertIcon />

        <AlertDescription overflowWrap={'break-word'} flexWrap={'wrap'}>
          <TranslatedMessage message={error} />
        </AlertDescription>

        {typeof onClose === 'function' && <CloseButton position="absolute" right={1} top={1} onClick={onClose} />}
      </Alert>
    </Fade>
  );
};

interface APIErrorProps extends AlertProps {
  error: TRPCClientErrorLike<API>;
  onClose?: () => void;
  formatError?: APIErrorFormatter;
}

export type APIError = TRPCClientErrorLike<API>;
export const APIError: React.FC<APIErrorProps> = (props) => {
  const { error, onClose, formatError, ...otherProps } = props;

  const formatter = formatError ?? defaultFormatter;
  const message = formatter(error);
  return (
    <Fade in>
      <Alert status="error" variant="top-accent" my="2" maxW={'100%'} {...otherProps}>
        <AlertIcon />

        <AlertDescription overflowWrap={'break-word'} flexWrap={'wrap'}>
          <TranslatedMessage message={message || errorMessages.internalError} />
        </AlertDescription>

        {typeof onClose === 'function' && <CloseButton position="absolute" right={1} top={1} onClick={onClose} />}
      </Alert>
    </Fade>
  );
};
