import {
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { TranslatedHeading, TranslatedMessage } from '../../i18n';
import { commonScenesMessages } from '../../i18n/commonMessages';
import { commonSubscriptionMessages } from '../Subscriptions/commonSubscriptionMessages';
import { FaBolt as SubscriptionIcon } from 'react-icons/fa6';
import { SubscribeToPlansAccordion } from '../Subscriptions/SubscribeToPlans/SubscribeToPlansAccordion';
import { SubscribeToPlanButton } from '../Subscriptions/SubscribeToPlans/SubscribeToPlanButton';

export const SubscribeToPlanDrawerButton: React.FC<ButtonProps> = (props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button
        leftIcon={<SubscriptionIcon />}
        gap={1}
        iconSpacing={[0, 2]}
        alignItems={'center'}
        variant={'subscriptionGradient'}
        onClick={onOpen}
        {...props}
      >
        <TranslatedMessage message={commonSubscriptionMessages.upgradeToProTitle} />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) grayscale(.5)" />
        <ModalContent>
          <ModalHeader>
            <TranslatedHeading message={commonScenesMessages.plans} size="lg" />
          </ModalHeader>
          <ModalCloseButton />

          {isOpen && (
            <ModalBody>
              <Flex direction={'column'} gap={4}>
                <TranslatedMessage textAlign={'start'} message={commonSubscriptionMessages.planSubscriptionDescription} fontSize="lg" />

                <SubscribeToPlansAccordion SubscriptionButton={SubscribeToPlanButton} />
              </Flex>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
