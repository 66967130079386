import { getTimezoneOffset } from 'date-fns-tz';
import { generateUniqueStringId } from './IDGeneration';
import { assertUnreachable, validateArray, validateNonEmptyObject, validateNumber, validateObjectKey, validateString } from './validations';

export enum AutomationTemplateType {
  SIMPLE_COMPARATOR = 'simpleComparator',
  SCHEDULE = 'schedule',
  MULTIPLE_SCHEDULE = 'multipleSchedule',
  CYCLE_SCHEDULE = 'cycleSchedule',
  ADVANCED = 'advanced',
  MULTI_RANGE_COMPARATOR = 'multiRangeComparator',
  PULSES = 'pulses',
}

// operators
export enum ComparisonOperator {
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
}

export enum LogicalOperator {
  AND = 'and',
  OR = 'or',
}

// nodes
export enum NodeType {
  COMPARISON = 'comparison',
  LOGICAL = 'logical',
}

export enum ReferenceType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
  TIME = 'time',
}

/**
 * Daily by default
 * Optionally can be set for custom days of week:
 * 0 - Sun
 * 1 - Mon
 * ...
 */
export interface TimeValue {
  type: ReferenceType.TIME;
  hours: number;
  minutes: number;
  seconds: number;
  daysOfWeek?: Array<number>;
}

export interface DynamicReference {
  type: ReferenceType.DYNAMIC;
  deviceId: number;
  moduleId: number;
  ioId: number;
}

export interface StaticReference {
  type: ReferenceType.STATIC;
  value: number;
}

// only one value, the another one is the current time
export interface TimeComparisonNode {
  id: string;
  type: NodeType.COMPARISON;
  operator: ComparisonOperator;
  value: TimeValue;
}

export interface IOComparisonNode {
  id: string;
  type: NodeType.COMPARISON;
  operator: ComparisonOperator;
  value: DynamicReference;
  reference: StaticReference;
  safetyState: number;
}

export type ComparisonNode = TimeComparisonNode | IOComparisonNode;

export interface LogicalNode {
  id: string;
  type: NodeType.LOGICAL;
  operator: LogicalOperator;
  children: Array<TriggerNode>;
}

export type TriggerNode = ComparisonNode | LogicalNode;

export interface TimeBranch extends LogicalNode {
  children: [TimeComparisonNode, TimeComparisonNode];
}

export interface RangeComparisonBranch extends LogicalNode {
  operator: LogicalOperator.OR;
  children: [IOComparisonNode, IOComparisonNode];
}

export type ComparisonBranch = IOComparisonNode | RangeComparisonBranch;

/**
 * This should map the trigger boolean value to
 * a different numeric one.
 * We may use it when analog outputs are implemented
 */
export enum TransferFunction {
  IDENTITY = 'identity',
}

/**
 * This allows for more dynamic automations without the need to overly complicate triggers.
 * For instance, if we want the output to pulse every 15 seconds during a specific time frame.
 */
export enum OutputModeType {
  CONSTANT = 'constant',
  PULSE = 'pulse',
}

export interface OutputModeConstant {
  type: OutputModeType.CONSTANT;
}

export interface OutputModePulse {
  type: OutputModeType.PULSE;
  timeOn: number;
  timeOff: number;
}

export type OutputMode = OutputModeConstant | OutputModePulse;

/**
 * This should be use for protecting external devices
 * connected to our outputs
 */
export enum ProtectionType {
  DEBOUNCE = 'debounce',
}

interface DebounceProtection {
  delayInSeconds: number;
}

export type Protection = DebounceProtection;

export type Automation = {
  trigger: TriggerNode;
  transfer: TransferFunction;
  outputMode: OutputMode;
  protection?: Protection;
};

/**
 * This is for minifying an automation for devices
 */

enum MinifiedNodeType {
  IO_COMPARISON = 0,
  TIME_COMPARISON = 1,
  LOGICAL = 2,
}

const MinifiedOutputModeMap: Record<OutputModeType, number> = {
  [OutputModeType.CONSTANT]: 0,
  [OutputModeType.PULSE]: 1,
};

const MinifiedLogicalOperatorMap: Record<LogicalOperator, number> = {
  [LogicalOperator.OR]: 0,
  [LogicalOperator.AND]: 1,
};

const MinifiedComparisonOperatorMap: Record<ComparisonOperator, number> = {
  [ComparisonOperator.LessThan]: 0,
  [ComparisonOperator.GreaterThan]: 1,
};

type MinifiedLogicalNode = {
  ty: MinifiedNodeType.LOGICAL; // node type
  op: number; // operator
  ch: Array<MinifiedNode>; // children
};

type MinifiedIOComparisonNode = {
  ty: MinifiedNodeType; // node type
  sp: number; // set point
  sv: number; // safety value
  cty: number; // comparison type
  ma: number; // module id
  iok: number; // io id
};

type MinifiedTimeComparisonNode = {
  ty: MinifiedNodeType; // node type
  sp: number; // set point
  cty: number; // comparison type
  dy?: number; // days of weeks mask
};

type MinifiedNode = MinifiedLogicalNode | MinifiedIOComparisonNode | MinifiedTimeComparisonNode;
export interface MinifiedAutomation {
  rt: MinifiedNode; // trigger node
  md: number; // output mode
  to?: number; // time on
  tf?: number; // time off
  dd?: number; // debounce delay
}

/**
 *
 * boolean validation checking
 *
 */
export function isValidLogicalOperator(operator: string): boolean {
  return Object.values(LogicalOperator).includes(operator as LogicalOperator);
}

export function isValidComparisonOperator(operator: string): boolean {
  return Object.values(ComparisonOperator).includes(operator as ComparisonOperator);
}

export function isValidNodeType(type: string): boolean {
  return Object.values(NodeType).includes(type as NodeType);
}

export function isValidReferenceType(type: string): boolean {
  return Object.values(ReferenceType).includes(type as ReferenceType);
}

export function isValidTransferFunction(functionName: string): boolean {
  return Object.values(TransferFunction).includes(functionName as TransferFunction);
}

export function isValidOutputAdaptation(adaptation: string): boolean {
  return Object.values(OutputModeType).includes(adaptation as OutputModeType);
}

export function isValidAutomationTemplateType(templateType: string): templateType is AutomationTemplateType {
  return Object.values(AutomationTemplateType).includes(templateType as AutomationTemplateType);
}

/**
 *
 * Assertions
 *
 */
export function validateNodeType(type: string): asserts type is NodeType {
  if (!Object.values(NodeType).includes(type as NodeType)) {
    throw new Error('Invalid node type');
  }
}

export function validateComparisonOperator(operator: string): asserts operator is ComparisonOperator {
  if (!Object.values(ComparisonOperator).includes(operator as ComparisonOperator)) {
    throw new Error('Invalid comparison operator');
  }
}

export function validateReferenceType(type: string): asserts type is ReferenceType {
  if (!Object.values(ReferenceType).includes(type as ReferenceType)) {
    throw new Error('Invalid reference type');
  }
}

export function validateLogicalOperator(operator: string): asserts operator is LogicalOperator {
  if (!Object.values(LogicalOperator).includes(operator as LogicalOperator)) {
    throw new Error('Invalid logical operator');
  }
}

export function validateTemplate(template: string): asserts template is AutomationTemplateType {
  if (!Object.values(AutomationTemplateType).includes(template as AutomationTemplateType)) {
    throw new Error('Invalid template type');
  }
}

export function validateTimeValue(timeValue: unknown): asserts timeValue is TimeValue {
  validateNonEmptyObject(timeValue);

  validateObjectKey(timeValue, 'type');
  validateObjectKey(timeValue, 'hours');
  validateObjectKey(timeValue, 'minutes');
  validateObjectKey(timeValue, 'seconds');

  const { type, hours, minutes, seconds, daysOfWeek } = timeValue;

  validateString(type);
  if (type !== ReferenceType.TIME) {
    throw new Error('Invalid time value type');
  }

  validateNumber(hours);
  if (hours < 0 || hours > 23) {
    throw new Error('Hours must be between 0 and 23');
  }

  validateNumber(minutes);
  if (minutes < 0 || minutes > 59) {
    throw new Error('Minutes must be between 0 and 59');
  }

  validateNumber(seconds);
  if (seconds < 0 || seconds > 59) {
    throw new Error('Seconds must be between 0 and 59');
  }

  if (daysOfWeek !== undefined) {
    validateArray(daysOfWeek);
    daysOfWeek.forEach((day) => {
      validateNumber(day);

      if (day < 0 || day > 6) {
        throw new Error('Day of week must be between 0 (Sunday) and 6 (Saturday)');
      }
    });
  }
}

export function validateIOValue(ioValue: unknown): asserts ioValue is DynamicReference {
  validateNonEmptyObject(ioValue);

  validateObjectKey(ioValue, 'deviceId');
  validateObjectKey(ioValue, 'moduleId');
  validateObjectKey(ioValue, 'ioId');

  const { ioId, moduleId, deviceId } = ioValue;

  validateNumber(deviceId);
  validateNumber(moduleId);
  validateNumber(ioId);
}

export function validateStaticValue(staticValue: unknown): asserts staticValue is StaticReference {
  validateNonEmptyObject(staticValue);
  validateObjectKey(staticValue, 'value');

  const { value } = staticValue;

  validateNumber(value);
}

export function validateTransferFunction(functionName: string): asserts functionName is TransferFunction {
  if (!Object.values(TransferFunction).includes(functionName as TransferFunction)) {
    throw new Error('Invalid transfer function');
  }
}

export function validateOutputModeType(mode: string): asserts mode is OutputModeType {
  if (!Object.values(OutputModeType).includes(mode as OutputModeType)) {
    throw new Error('Invalid output mode');
  }
}

export function validateOutputMode(value: unknown): asserts value is OutputMode {
  validateNonEmptyObject(value);
  validateObjectKey(value, 'type');

  const { type } = value;

  validateString(type);
  validateOutputModeType(type);

  switch (type) {
    case OutputModeType.CONSTANT:
      break;

    case OutputModeType.PULSE: {
      validateObjectKey(value, 'timeOn');
      validateObjectKey(value, 'timeOff');

      const { timeOn, timeOff } = value;

      validateNumber(timeOn);
      validateNumber(timeOff);
      break;
    }
    default:
      assertUnreachable('Invalid output mode type', type);
  }
}

export function validateNode(node: unknown): asserts node is TriggerNode {
  validateNonEmptyObject(node);

  validateObjectKey(node, 'id');
  validateObjectKey(node, 'type');
  validateObjectKey(node, 'operator');

  const { id, type, operator } = node;

  validateString(id);
  validateString(operator);
  validateString(type);
  validateNodeType(type);

  switch (type) {
    case NodeType.COMPARISON: {
      const { value } = node;

      validateNonEmptyObject(value);
      validateObjectKey(value, 'type');
      validateString(value.type);
      validateReferenceType(value.type);

      const { type: referenceType } = value;

      validateComparisonOperator(operator);

      if (referenceType === ReferenceType.TIME) {
        validateTimeValue(value);
        break;
      }

      const { reference, safetyState } = node;

      validateIOValue(value);
      validateStaticValue(reference);
      validateNumber(safetyState);

      break;
    }

    case NodeType.LOGICAL: {
      validateLogicalOperator(operator);
      validateObjectKey(node, 'children');

      const { children } = node;
      validateArray(children);

      if (children.length < 2) {
        throw new Error('Insufficient children.');
      }

      children.forEach(validateNode);
      break;
    }
  }
}

// this only check the schema, it does not check valid children in logical
export function softValidateNode(node: unknown): asserts node is TriggerNode {
  validateNonEmptyObject(node);

  validateObjectKey(node, 'id');
  validateObjectKey(node, 'type');
  validateObjectKey(node, 'operator');

  const { id, type, operator } = node;

  validateString(id);
  validateString(operator);
  validateString(type);
  validateNodeType(type);

  switch (type) {
    case NodeType.COMPARISON: {
      const { value } = node;

      validateNonEmptyObject(value);
      validateObjectKey(value, 'type');
      validateString(value.type);
      validateReferenceType(value.type);

      const { type: referenceType } = value;

      validateComparisonOperator(operator);

      if (referenceType === ReferenceType.TIME) {
        validateTimeValue(value);
        break;
      }

      const { reference, safetyState } = node;

      validateIOValue(value);
      validateStaticValue(reference);
      validateNumber(safetyState);

      break;
    }

    case NodeType.LOGICAL: {
      validateObjectKey(node, 'children');

      const { children } = node;
      validateArray(children);

      children.forEach(softValidateNode);
      break;
    }
  }
}

export function validateAutomation(automation: unknown): asserts automation is Automation {
  validateNonEmptyObject(automation);

  validateObjectKey(automation, 'trigger');
  validateObjectKey(automation, 'transfer');
  validateObjectKey(automation, 'outputMode');

  const { trigger, transfer, outputMode } = automation;

  validateNode(trigger);

  validateString(transfer);
  validateTransferFunction(transfer);

  validateNonEmptyObject(outputMode);
  validateOutputMode(outputMode);

  if ('protection' in automation) {
    const protection = automation.protection;

    validateNonEmptyObject(protection);
    validateObjectKey(protection, 'delayInSeconds');
    validateNumber(protection.delayInSeconds);
  }
}

// minified
function validateMinifiedIOComparisonNode(node: MinifiedIOComparisonNode): void {
  validateNumber(node.sp);
  validateNumber(node.cty);
  validateNumber(node.sv);
  validateNumber(node.ma);
  validateNumber(node.iok);
}

function validateMinifiedTimeComparisonNode(node: MinifiedTimeComparisonNode): void {
  validateNumber(node.sp);
  validateNumber(node.cty);
  if ('dy' in node) {
    validateNumber(node.dy);
  }
}

function validateMinifiedLogicalNode(node: MinifiedLogicalNode): void {
  validateNumber(node.op);
  validateArray(node.ch);
  node.ch.forEach(validateMinifiedNode);
}

function validateMinifiedNode(node: unknown): asserts node is MinifiedNode {
  validateNonEmptyObject(node);
  validateObjectKey(node, 'ty');

  switch (node.ty) {
    case MinifiedNodeType.IO_COMPARISON:
      validateMinifiedIOComparisonNode(node as MinifiedIOComparisonNode);
      break;

    case MinifiedNodeType.TIME_COMPARISON:
      validateMinifiedTimeComparisonNode(node as MinifiedTimeComparisonNode);
      break;

    case MinifiedNodeType.LOGICAL:
      validateMinifiedLogicalNode(node as MinifiedLogicalNode);
      break;

    default:
      throw new Error('Invalid minified node type');
  }
}

export function validateMinifiedAutomation(minifiedAutomation: unknown): asserts minifiedAutomation is MinifiedAutomation {
  validateNonEmptyObject(minifiedAutomation);

  validateObjectKey(minifiedAutomation, 'rt');
  validateObjectKey(minifiedAutomation, 'md');
  const { rt, md, to, tf, dd } = minifiedAutomation;

  validateMinifiedNode(rt);
  validateNumber(md);

  if (to !== undefined) {
    validateNumber(to);
  }

  if (tf !== undefined) {
    validateNumber(tf);
  }

  if (dd !== undefined) {
    validateNumber(dd);
  }
}

/**
 *
 * Builders
 *
 */
export class AutomationBuilder {
  trigger: TriggerNode;
  transfer: TransferFunction;
  outputMode: OutputMode;
  protection?: Protection;

  constructor(trigger: TriggerNode, transfer?: TransferFunction, outputMode?: OutputMode, protection?: Protection) {
    this.trigger = trigger;
    this.transfer = transfer ?? TransferFunction.IDENTITY;
    this.outputMode = outputMode ?? { type: OutputModeType.CONSTANT };
    this.protection = protection;
  }

  get(): Automation {
    return {
      trigger: this.trigger,
      transfer: this.transfer,
      outputMode: this.outputMode,
      protection: this.protection,
    };
  }

  getDevice(): number | null {
    const extractDeviceId = (node: TriggerNode): number | null => {
      if (node.type === NodeType.COMPARISON && 'reference' in node) {
        return node.value.deviceId;
      } else if (node.type === NodeType.LOGICAL) {
        // iterate children
        for (const child of node.children) {
          const result = extractDeviceId(child);
          if (result !== null) return result;
        }
      }

      return null;
    };

    return extractDeviceId(this.trigger);
  }

  getIOs(): Array<number> {
    const ios: Array<number> = [];

    const pushIOIdsToArray = (node: TriggerNode) => {
      if (node.type === NodeType.COMPARISON && 'reference' in node) {
        ios.push(node.value.ioId);
      } else if (node.type === NodeType.LOGICAL) {
        node.children.forEach(pushIOIdsToArray);
      }
    };

    pushIOIdsToArray(this.trigger);

    return ios;
  }

  serialize(): string {
    return JSON.stringify(this.get());
  }

  minify(timezone?: string): MinifiedAutomation {
    const offsetInMilliSeconds = timezone ? getTimezoneOffset(timezone) : 0;
    const offsetInSeconds = isNaN(offsetInMilliSeconds) ? 0 : offsetInMilliSeconds / 1000;
    const timezoneOffsetInHours = offsetInSeconds / 3600;
    // trigger nodes
    const minifyTriggerNode = (node: TriggerNode): MinifiedNode => {
      if (node.type === NodeType.LOGICAL) {
        try {
          const timeBranchBuilder = TimeBranchBuilder.fromRaw(JSON.stringify(node), timezoneOffsetInHours);

          return timeBranchBuilder.minify();
        } catch (err) {
          // Logical
          return {
            ty: MinifiedNodeType.LOGICAL,
            op: MinifiedLogicalOperatorMap[node.operator],
            ch: node.children.map(minifyTriggerNode),
          };
        }
      } else {
        // io comparison node
        if ('reference' in node) {
          return {
            ty: MinifiedNodeType.IO_COMPARISON,
            cty: MinifiedComparisonOperatorMap[node.operator],
            sp: node.reference.value * 100, // devices use hundredths,
            sv: node.safetyState,
            ma: node.value.moduleId,
            iok: node.value.ioId,
          };
        } else {
          /**
           * if I get a negative number after applying the offset,
           * it means the right time should be in the previous day.
           * but the comparison is daily so, adding 1 day in seconds is enough
           */
          const secondsFromStartOfDay = offsetInSeconds + node.value.hours * 3600 + node.value.minutes * 60 + node.value.seconds;
          const secondsFromStartOfDayFixed = secondsFromStartOfDay < 0 ? secondsFromStartOfDay + 24 * 3600 : secondsFromStartOfDay;
          const daysOfWeekMask = node.value.daysOfWeek?.reduce((mask, day) => Math.pow(2, day) + mask, 0);

          return {
            ty: MinifiedNodeType.TIME_COMPARISON,
            cty: MinifiedComparisonOperatorMap[node.operator],
            sp: secondsFromStartOfDayFixed,
            dy: daysOfWeekMask,
          };
        }
      }
    };

    const automation = this.get();

    // debounce delay in seconds
    const dd = automation.protection?.delayInSeconds;

    return {
      rt: minifyTriggerNode(automation.trigger),
      md: MinifiedOutputModeMap[automation.outputMode.type],
      to: automation.outputMode.type === OutputModeType.PULSE ? automation.outputMode.timeOn : undefined,
      tf: automation.outputMode.type === OutputModeType.PULSE ? automation.outputMode.timeOff : undefined,
      dd,
    };
  }

  /**
   /**
    * This function calculates the useful size of the automation that will be handled by the device.
    * It is significantly smaller than the actual size it occupies in the database.
    * The creation/update mutation handles the database size restriction.
    * This function is simply used to estimate the real weight that the device will need to manage.
    */
  size(): number {
    const minified = this.minify();

    return JSON.stringify(minified).length;
  }

  updateTrigger(newTrigger: TriggerNode) {
    this.trigger = newTrigger;
  }

  updateTransfer(newTransfer: TransferFunction) {
    this.transfer = newTransfer;
  }

  updateOutput(newOutputMode: OutputMode) {
    this.outputMode = newOutputMode;
  }

  updateProtection(newProtection: Protection) {
    this.protection = newProtection;
  }

  static fromRaw(raw: string): AutomationBuilder {
    const json = JSON.parse(raw) as unknown;

    validateAutomation(json);

    return new AutomationBuilder(json.trigger, json.transfer, json.outputMode, json.protection);
  }
}

export class IOComparisonNodeBuilder {
  id: string;
  operator: ComparisonOperator;
  value: DynamicReference;
  reference: StaticReference;
  safetyState: number;

  constructor(operator: ComparisonOperator, value: DynamicReference, reference: StaticReference, safetyState: number) {
    this.id = generateUniqueStringId();

    this.operator = operator;
    this.value = value;
    this.reference = reference;
    this.safetyState = safetyState;
  }

  build(): IOComparisonNode {
    return {
      id: this.id,
      type: NodeType.COMPARISON,
      operator: this.operator,
      value: this.value,
      reference: this.reference,
      safetyState: this.safetyState,
    };
  }

  updateOperator(operator: ComparisonOperator) {
    this.operator = operator;
  }

  updateValue(value: DynamicReference) {
    this.value = value;
  }

  updateReference(value: StaticReference) {
    this.reference = value;
  }

  validate() {
    validateNode({
      id: this.id,
      type: NodeType.COMPARISON,
      operator: this.operator,
      value: this.value,
      reference: this.reference,
      safetyState: this.safetyState,
    });
  }
}

export class TimeComparisonNodeBuilder {
  id: string;
  operator: ComparisonOperator;
  value: TimeValue;

  constructor(operator: ComparisonOperator, time: TimeValue) {
    this.id = generateUniqueStringId();

    this.operator = operator;
    this.value = time;
  }

  build(): TimeComparisonNode {
    return {
      id: this.id,
      type: NodeType.COMPARISON,
      operator: this.operator,
      value: this.value,
    };
  }

  validate() {
    validateNode({
      id: this.id,
      type: NodeType.COMPARISON,
      operator: this.operator,
      value: this.value,
    });
  }
}

export class LogicalNodeBuilder {
  id: string;
  operator: LogicalOperator;
  children: Array<TriggerNode>;

  constructor(operator: LogicalOperator, children: Array<TriggerNode>) {
    this.id = generateUniqueStringId();

    this.operator = operator;
    this.children = children;
  }

  addChildren(newNode: TriggerNode) {
    this.children.push(newNode);
  }

  cleanChildren() {
    this.children = [];
  }

  removeChildren(nodeId: string) {
    const filtered = this.children.filter((node) => node.id !== nodeId);

    this.children = filtered;
  }

  replaceChildren(nodeId: string, newNode: TriggerNode) {
    const updated = this.children.map((node) => (node.id !== nodeId ? newNode : node));

    this.children = updated;
  }
  updateOperator(newOperator: LogicalOperator) {
    this.operator = newOperator;
  }

  validate() {
    validateNode({
      id: this.id,
      type: NodeType.LOGICAL,
      operator: this.operator,
      children: this.children,
    });
  }

  build(): LogicalNode {
    return {
      id: this.id,
      type: NodeType.LOGICAL,
      operator: this.operator,
      children: this.children,
    };
  }

  serialize(): string {
    const node = this.build();

    return JSON.stringify(node);
  }
}

/**
 * This class is for building the time branches of automations.
 * It contains the logic for weekly repetition and node correction
 * when we want the automation to start at a certain time on one day
 * and end at a different time on the next day.
 *
 * The main purpose is to handle the logic for testing and converting everything to UTC,
 * including weekly repetition, dates, and consequently the AND/OR nodes.
 */

interface MinifiedTimeBranch extends MinifiedLogicalNode {
  ty: MinifiedNodeType.LOGICAL;
  ch: [MinifiedTimeComparisonNode, MinifiedTimeComparisonNode];
}
export class TimeBranchBuilder {
  id: string;
  start: Date;
  end: Date;
  daysOfWeek?: Array<number>;
  timezoneOffsetInHours: number;

  constructor(start: Date, end: Date, daysOfWeek?: Array<number>, timezoneOffsetInHours?: number, id?: string) {
    const startTime = start.getTime();
    const endTime = end.getTime();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    if (Math.abs(endTime - startTime) <= 0) {
      throw new Error('The difference between start and end time must be greater than 0.');
    }

    if (Math.abs(endTime - startTime) >= oneDayInMilliseconds) {
      throw new Error('The difference between start and end time must be less than 1 day.');
    }

    this.id = id ?? generateUniqueStringId();

    /**
     * We want to remove timezone dependency so, lets start removing date difference and
     * keep focus on time
     */
    const today = new Date();
    this.start = new Date(
      Date.UTC(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        start.getUTCHours(),
        start.getUTCMinutes(),
        start.getUTCSeconds()
      )
    );
    this.end = new Date(
      Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), end.getUTCHours(), end.getUTCMinutes(), end.getUTCSeconds())
    );
    this.daysOfWeek = daysOfWeek;
    this.timezoneOffsetInHours = timezoneOffsetInHours ?? (-1 * new Date().getTimezoneOffset()) / 60;
  }

  /**
   * This example was thought for a negative timezone. For positive timezones, the logic is similar but reversed.
   * When dates are selected from a picker, different scenarios can occur:
   *
   * 1. startUTCTime > endUTCTime with the same UTCDate: This happens when an earlier date is selected as the end.
   *    Users might want the period to start at night and end in the morning.
   *
   * 2. startUTCTime > endUTCTime with a different UTCDate: Similar situation, but the end time falls within the offset range.
   *    For example, 11 PM (UTC-3) means the end will be on the next day.
   *
   * 3. startUTCTime < endUTCTime with a different UTCDate: Selected within the same local timezone day, but timezone correction
   *    results in different UTC days.
   *
   * 4. startUTCTime < endUTCTime with the same UTCDate: This is the most straightforward scenario where the start and end times
   *    are within the same UTC day, and no additional adjustments are needed for the days array.
   *
   * We use getUTCHour, getUTCMinute, and getUTCSeconds functions, so we must consider these scenarios
   * to decide on the logical AND/OR node and adjust the days array.
   */
  build(): TimeBranch {
    const isStartAfterEnd = TimeBranchBuilder.isStartAfterEnd(this.start, this.end);

    let startUTCDays, endUTCDays;

    if (this.daysOfWeek !== undefined) {
      startUTCDays = this.daysOfWeek;
      endUTCDays = this.daysOfWeek;

      // this is to avoid using getDate to remove runtime timezone dependency
      const startDateInLocalTimezone = new Date(this.start.getTime() + this.timezoneOffsetInHours * 60 * 60 * 1000);
      const endDateInLocalTimezone = new Date(this.end.getTime() + this.timezoneOffsetInHours * 60 * 60 * 1000);

      const doesStartNeedToShift = startDateInLocalTimezone.getUTCDate() !== this.start.getUTCDate();
      if (doesStartNeedToShift) {
        //  compare time to avoid issues with comparing dates like 31-1
        const isAfterUTCDay = startDateInLocalTimezone.getTime() > this.start.getTime();

        // shift depending on timezone offset direction
        startUTCDays = isAfterUTCDay ? TimeBranchBuilder.decrementDays(this.daysOfWeek) : TimeBranchBuilder.incrementDays(this.daysOfWeek);
      }

      const doesEndNeedToShift = endDateInLocalTimezone.getUTCDate() !== this.end.getUTCDate();
      if (doesEndNeedToShift) {
        //  compare time to avoid issues with comparing dates like 31-1
        const isAfterUTCDay = endDateInLocalTimezone.getTime() > this.end.getTime();

        // shift depending on timezone offset direction
        endUTCDays = isAfterUTCDay ? TimeBranchBuilder.decrementDays(this.daysOfWeek) : TimeBranchBuilder.incrementDays(this.daysOfWeek);
      }

      /**
       * Increment the endUTCDays array by one day only when the start time is after the end time
       * and both the start and end dates have either been shifted or not shifted at all.
       */
      if (isStartAfterEnd && ((doesStartNeedToShift && doesEndNeedToShift) || (!doesStartNeedToShift && !doesEndNeedToShift))) {
        endUTCDays = TimeBranchBuilder.incrementDays(endUTCDays);
      }
    }

    const startTimeValue: TimeValue = {
      type: ReferenceType.TIME,
      hours: this.start.getUTCHours(),
      minutes: this.start.getUTCMinutes(),
      seconds: this.start.getUTCSeconds(),
      daysOfWeek: startUTCDays,
    };

    const endTimeValue: TimeValue = {
      type: ReferenceType.TIME,
      hours: this.end.getUTCHours(),
      minutes: this.end.getUTCMinutes(),
      seconds: this.end.getUTCSeconds(),
      daysOfWeek: endUTCDays,
    };

    const startNode = new TimeComparisonNodeBuilder(ComparisonOperator.GreaterThan, startTimeValue).build();
    const endNode = new TimeComparisonNodeBuilder(ComparisonOperator.LessThan, endTimeValue).build();

    const operator = isStartAfterEnd ? LogicalOperator.OR : LogicalOperator.AND;

    return {
      id: this.id,
      type: NodeType.LOGICAL,
      operator,
      children: [startNode, endNode],
    };
  }

  getStartAndEndDates() {
    return { start: this.start, end: this.end };
  }

  getDaysOfWeek() {
    // days being undefined means that it is the entire week
    if (this.daysOfWeek === undefined) {
      return [0, 1, 2, 3, 4, 5, 6];
    }

    return this.daysOfWeek;
  }

  serialize(): string {
    return JSON.stringify(this.build());
  }

  minify(): MinifiedTimeBranch {
    // timezone offset in seconds
    const offsetInSeconds = this.timezoneOffsetInHours * 3600;

    // build start and end time for devices in utc
    const startInUTCSeconds = this.start.getUTCHours() * 3600 + this.start.getUTCMinutes() * 60 + this.start.getUTCSeconds();
    const endInUTCSeconds = this.end.getUTCHours() * 3600 + this.end.getUTCMinutes() * 60 + this.end.getUTCSeconds();

    // build start and end time for devices in corresponding timezone
    const startInSeconds = offsetInSeconds + startInUTCSeconds;
    const endInSeconds = offsetInSeconds + endInUTCSeconds;

    // normalize them in case there is day shifting
    const startInSecondsNormalized = TimeBranchBuilder.normalizeDaySeconds(startInSeconds);
    const endInSecondsNormalized = TimeBranchBuilder.normalizeDaySeconds(endInSeconds);

    // check day shifting for end time
    const isEndInNextDay = startInSecondsNormalized > endInSecondsNormalized;
    const operator = isEndInNextDay ? LogicalOperator.OR : LogicalOperator.AND;

    // build and encode days of week based on shifting
    const startDaysOfWeekMask = this.daysOfWeek?.reduce((mask, day) => Math.pow(2, day) + mask, 0);
    const endDaysOfWeekMask =
      isEndInNextDay && this.daysOfWeek
        ? TimeBranchBuilder.incrementDays(this.daysOfWeek).reduce((mask, day) => Math.pow(2, day) + mask, 0)
        : startDaysOfWeekMask;

    const minifiedNode: MinifiedTimeBranch = {
      ty: MinifiedNodeType.LOGICAL,
      op: MinifiedLogicalOperatorMap[operator],
      ch: [
        {
          // start
          ty: MinifiedNodeType.TIME_COMPARISON,
          cty: MinifiedComparisonOperatorMap[ComparisonOperator.GreaterThan],
          sp: startInSecondsNormalized,
          dy: startDaysOfWeekMask,
        },
        {
          // end
          ty: MinifiedNodeType.TIME_COMPARISON,
          cty: MinifiedComparisonOperatorMap[ComparisonOperator.LessThan],
          sp: endInSecondsNormalized,
          dy: endDaysOfWeekMask,
        },
      ],
    };

    return minifiedNode;
  }

  static fromRaw(raw: string, timezoneOffsetInHours?: number): TimeBranchBuilder {
    const node = JSON.parse(raw) as TriggerNode;

    validateNodeIsLogicalNode(node);

    if (node.children.length !== 2) {
      throw new Error('Time branch must have 2 children');
    }

    const [startNode, endNode] = node.children;

    validateNodeIsStartTimeComparisonNode(startNode);
    validateNodeIsEndTimeComparisonNode(endNode);

    const today = new Date();
    const startDate = new Date(
      Date.UTC(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        startNode.value.hours,
        startNode.value.minutes,
        startNode.value.seconds
      )
    );

    const endDate = new Date(
      Date.UTC(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        endNode.value.hours,
        endNode.value.minutes,
        endNode.value.seconds
      )
    );

    // by default use UTC daysOfWeek
    let startDaysOfWeek, endDaysOfWeek;

    const isStartAfterEnd = TimeBranchBuilder.isStartAfterEnd(startDate, endDate);

    // validate operator
    if (isStartAfterEnd) {
      validateNodeIsLogicalORNode(node);
    } else {
      validateNodeIsLogicalANDNode(node);
    }

    const timezoneOffset = timezoneOffsetInHours ?? (-1 * new Date().getTimezoneOffset()) / 60;

    const startDateInLocalTimezone = new Date(startDate.getTime() + timezoneOffset * 60 * 60 * 1000);
    const endDateInLocalTimezone = new Date(endDate.getTime() + timezoneOffset * 60 * 60 * 1000);

    // validate days of week are converted to UTC days
    if (startNode.value.daysOfWeek !== undefined && endNode.value.daysOfWeek !== undefined) {
      startDaysOfWeek = startNode.value.daysOfWeek;
      endDaysOfWeek = endNode.value.daysOfWeek;

      // check UTC timezone shifting
      if (startDateInLocalTimezone.getUTCDate() !== startDate.getUTCDate()) {
        // reverse timezone shifting. compare time to avoid issues with comparing dates like 31-1
        const isStartAfterUTCDay = startDateInLocalTimezone.getTime() > startDate.getTime();

        startDaysOfWeek = isStartAfterUTCDay
          ? TimeBranchBuilder.incrementDays(startNode.value.daysOfWeek)
          : TimeBranchBuilder.decrementDays(startNode.value.daysOfWeek);
      }

      // we apply the inverse corrections to check if daysOfWeek of nodes are valid
      if (endDateInLocalTimezone.getUTCDate() !== endDate.getUTCDate()) {
        // reverse timezone shifting.  compare time to avoid issues with comparing dates like 31-1
        const isEndAfterUTCDay = endDateInLocalTimezone.getTime() > endDate.getTime();

        endDaysOfWeek = isEndAfterUTCDay
          ? TimeBranchBuilder.incrementDays(endNode.value.daysOfWeek)
          : TimeBranchBuilder.decrementDays(endNode.value.daysOfWeek);
      }

      if (isStartAfterEnd && startDateInLocalTimezone.getUTCDate() === endDateInLocalTimezone.getUTCDate()) {
        endDaysOfWeek = TimeBranchBuilder.decrementDays(endDaysOfWeek);
      }
    }

    // endDate.setUTCDate(endDate.getUTCDate() + 1);
    TimeBranchBuilder.validateDaysOfWeekAreEqual(startDaysOfWeek, endDaysOfWeek);

    return new TimeBranchBuilder(startDate, endDate, startDaysOfWeek, timezoneOffsetInHours, node.id);
  }

  private static isStartAfterEnd(start: Date, end: Date): boolean {
    if (start.getUTCHours() > end.getUTCHours()) {
      return true;
    }

    if (start.getUTCHours() === end.getUTCHours()) {
      if (start.getUTCMinutes() > end.getUTCMinutes()) {
        return true;
      }

      if (start.getUTCMinutes() === end.getUTCMinutes()) {
        return start.getUTCSeconds() > end.getUTCSeconds();
      }
    }

    return false;
  }

  // mod 7 ensures 0-6 range
  private static incrementDays(days: Array<number>): Array<number> {
    return days.map((day) => (day + 1) % 7);
  }

  private static decrementDays(days: Array<number>): Array<number> {
    return days.map((day) => (day - 1 + 7) % 7);
  }

  private static validateDaysOfWeekAreEqual(days1: Array<number> | undefined, days2: Array<number> | undefined) {
    if (days1 === undefined && days2 !== undefined) {
      throw new Error('first array of the days of week is undefined');
    }

    if (days1 !== undefined && days2 === undefined) {
      throw new Error('second array of the days of week is undefined');
    }

    if (days1 && days2) {
      if (days1.length === days2.length) {
        const equalDaysOfWeek = days1.every((day, index) => day === days2[index]);

        if (!equalDaysOfWeek) {
          throw new Error('Days of week are not equal');
        }
      } else {
        throw new Error('Days of week have not equal length');
      }
    }
  }

  // this fix seconds depending on overflow if exists
  /**
   * Adjusts the given seconds to fit within a 24-hour day.
   * If the seconds are negative, it wraps around to the previous day.
   * If the seconds exceed the number of seconds in a day, it wraps around to the next day.
   */
  private static normalizeDaySeconds(seconds: number): number {
    if (seconds < 0) {
      return seconds + 24 * 3600;
    }

    return seconds % (24 * 3600);
  }
}

/**
 *
 *          TEMPLATES
 *
 */

/**
 * Time value is saved in UTC
 * So, we have to show it in corresponding user timezone
 */
export const timeValueToDate = (value: TimeValue): Date => {
  const { hours, minutes, seconds } = value;

  const currentDate = new Date();
  currentDate.setUTCHours(hours, minutes, seconds);

  return currentDate;
};

/**
 * Checks if automation type requires subscription
 */
export const isAutomationTypeSubscriptionRequired = (type: string): boolean => {
  if (
    type === AutomationTemplateType.ADVANCED ||
    type === AutomationTemplateType.MULTI_RANGE_COMPARATOR ||
    type === AutomationTemplateType.PULSES
  ) {
    return true;
  }

  return false;
};

/**
 *  Validates that automation OutputMode is of type PULSE
 */
export function validateOutputModeIsPulse(outputMode: OutputMode): asserts outputMode is OutputModePulse {
  if (outputMode.type !== OutputModeType.PULSE) {
    throw new Error('Output mode is not PULSE');
  }
}

/* IOComparison nodes */

/**
 *  Checks if Node is IOComparisonNode
 */
export function isIOComparisonNode(node: TriggerNode): node is IOComparisonNode {
  if (
    node.type === NodeType.COMPARISON &&
    node.value.type === ReferenceType.DYNAMIC &&
    'reference' in node &&
    node.reference.type === ReferenceType.STATIC
  ) {
    return true;
  }

  return false;
}

/**
 *  Checks if Node is IOComparisonBranch containing 1 or 2 IOComparisonNodes
 */
export const isComparisonBranch = (node: TriggerNode): node is ComparisonBranch => {
  // if its a single comparison
  if (isIOComparisonNode(node)) {
    return true;
  }

  // if its a nested comparison
  if (isLogicalORNode(node)) {
    if (node.children.length !== 2) {
      return false;
    }

    const [firstChild, secondChild] = node.children;

    if (isIOComparisonNode(firstChild) && isIOComparisonNode(secondChild)) {
      return true;
    }
  }

  return false;
};

/**
 *  Validates Node is ComparisonBranch containing 1 or 2 IOComparisonNodes
 */
export const validateNodeIsComparisonBranch = (node: TriggerNode): asserts node is ComparisonBranch => {
  if (!isComparisonBranch(node)) {
    throw new Error('Node is not a ComparisonBranch');
  }
};

/**
 *  Validates Node is IOComparisonNode
 */
export function validateNodeIsIOComparisonNode(node: TriggerNode): asserts node is IOComparisonNode {
  if (node.type !== NodeType.COMPARISON || node.value.type !== ReferenceType.DYNAMIC) {
    throw new Error('Node is not COMPARISON with DYNAMIC values');
  }

  if ('reference' in node && node.reference.type !== ReferenceType.STATIC) {
    throw new Error('Node must have a STATIC ReferenceType');
  }
}

/* Time Nodes */

/**
 *  Validates Node is TimeBranch, contemplating the case where end date is overflowing the end of day
 */
export function validateNodeIsTimeBranch(node: TriggerNode): asserts node is TimeBranch {
  if (node.type !== NodeType.LOGICAL) {
    throw new Error('Node is not a Logical');
  }

  if (node.children.length !== 2) {
    throw new Error('TIME branch must have 2 children');
  }

  if (node.operator !== LogicalOperator.AND && node.operator !== LogicalOperator.OR) {
    throw new Error('TIME branch must have AND or OR operator');
  }

  const [startNode, endNode] = node.children;

  validateNodeIsStartTimeComparisonNode(startNode);
  validateNodeIsEndTimeComparisonNode(endNode);

  const startDate = timeValueToDate(startNode.value);
  const endDate = timeValueToDate(endNode.value);

  const isInvalidORCondition = node.operator === LogicalOperator.OR && startDate < endDate;
  if (isInvalidORCondition) {
    throw new Error('endDate is exceeding the end of day and cannot be greater than start date');
  }

  const isInvalidANDCondition = node.operator === LogicalOperator.AND && startDate > endDate;
  if (isInvalidANDCondition) {
    throw new Error('startDate cannot be grater than endDate');
  }
}

/**
 *  Validates Node is TimeComparisonNode with GreaterThan operator
 */
export function validateNodeIsStartTimeComparisonNode(node: TriggerNode): asserts node is TimeComparisonNode {
  if (node.type !== NodeType.COMPARISON || node.value.type !== ReferenceType.TIME || node.operator !== ComparisonOperator.GreaterThan) {
    throw new Error('Node is not a COMPARISON node with TIME values and GreaterThan operator');
  }
}

/**
 *  Validates Node is TimeComparisonNode with LessThan operator
 */
export function validateNodeIsEndTimeComparisonNode(node: TriggerNode): asserts node is TimeComparisonNode {
  if (node.type !== NodeType.COMPARISON || node.value.type !== ReferenceType.TIME || node.operator !== ComparisonOperator.LessThan) {
    throw new Error('Node is not a COMPARISON node with TIME values and LessThan operator');
  }
}

/**
 * Checks Node is TimeComparisonNode
 */
export function isTimeComparisonNode(node: TriggerNode): node is TimeComparisonNode {
  return node.type === NodeType.COMPARISON && node.value.type === ReferenceType.TIME;
}

/**
 *  Validates Node is LogicalNode
 */
export function validateNodeIsLogicalNode(node: TriggerNode): asserts node is LogicalNode {
  if (node.type !== NodeType.LOGICAL) {
    throw new Error('Node is not a LOGICAL node');
  }
}

/**
 * Validates Node is LogicalNode with AND operator
 */
export function validateNodeIsLogicalANDNode(node: TriggerNode): asserts node is LogicalNode {
  if (node.type !== NodeType.LOGICAL || node.operator !== LogicalOperator.AND) {
    throw new Error('Node is not a LOGICAL node with AND operator');
  }
}

/**
 * Validates Node is LogicalNode with OR operator
 */
export function validateNodeIsLogicalORNode(node: TriggerNode): asserts node is LogicalNode {
  if (node.type !== NodeType.LOGICAL || node.operator !== LogicalOperator.OR) {
    throw new Error('Node is not a LOGICAL node with OR operator');
  }
}

/**
 * Checks Node is LogicalNode with AND operator
 */
export function isLogicalANDNode(node: TriggerNode): node is LogicalNode {
  return node.type === NodeType.LOGICAL && node.operator === LogicalOperator.AND;
}

/**
 * Checks Node is LogicalNode with OR operator
 */
export function isLogicalORNode(node: TriggerNode): node is LogicalNode {
  return node.type === NodeType.LOGICAL && node.operator === LogicalOperator.OR;
}
