import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { NotFound } from './404_NotFound';
import { BadRequest } from './400_BadRequest';
import { Unauthorized } from './401_Unauthorized';
import { Forbidden } from './403_Forbidden';
import { Internal } from './500_Internal';
import { getEnvs } from '../../utils/getEnvs';

/**
 * RouterErrorHandler manages various types of routing errors:
 *
 * 1. Known HTTP status errors (400, 401, 403, 404, 500) are handled by specific components.
 *
 * 2. For other errors, especially those related to dynamic module loading (which can occur
 *    during deployments when chunk names change), we attempt to reload the page. This refresh
 *    can often resolve issues by updating the manifest and clearing any temporary loading problems.
 *
 * 3. If the error persists after a maximum number of reloads, we let it propagate to the
 *    top-level error boundary (e.g., Sentry) for proper logging and reporting. This count is cleared
 *    in the error page, so, after refreshing or whatever, the cycle is reset.
 *
 * This approach helps to gracefully handle both expected routing errors and unexpected
 * issues that may arise from deployment-related inconsistencies.
 */
export const RELOAD_COUNT_CACHE_KEY = 'ROUTER_ERROR_RELOAD_COUNT';
export const RouterErrorHandler: React.FC = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 400:
        return <BadRequest />;

      case 401:
        return <Unauthorized />;

      case 403:
        return <Forbidden />;

      case 404:
        return <NotFound />;

      case 500:
      default:
        return <Internal />;
    }
  }

  return <Reloader error={error} />;
};

interface ReloaderProps {
  error: unknown;
}

const Reloader: React.FC<ReloaderProps> = (props) => {
  const { error } = props;

  const { env } = getEnvs();

  // we don't need more than one to refresh the manifest
  const maxReloads = 2;
  const reloadCount = parseInt(localStorage.getItem(RELOAD_COUNT_CACHE_KEY) || '0', 10);

  if (reloadCount < maxReloads) {
    localStorage.setItem(RELOAD_COUNT_CACHE_KEY, (reloadCount + 1).toString());

    if (env === 'production') {
      window.location.reload();
    }
  } else {
    throw error;
  }

  return null;
};
