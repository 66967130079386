import { TRPCClientError } from '@trpc/client';
import { APIUtils, getHTTPCodeFromTRPCError } from '../../../api/trpc';

export const createOverviewMetricsLoader = (utils: APIUtils) => async () => {
  try {
    const data = await utils.backoffice.metrics.overview.ensureData();

    return data;
  } catch (e) {
    if (e instanceof TRPCClientError) {
      const status = getHTTPCodeFromTRPCError(e);
      if (status) {
        throw new Response(e.message, { status });
      }
    }

    throw e;
  }
};

export const createSupportMetricsLoader = (utils: APIUtils) => async () => {
  try {
    const data = await utils.backoffice.metrics.support.ensureData();

    return data;
  } catch (e) {
    if (e instanceof TRPCClientError) {
      const status = getHTTPCodeFromTRPCError(e);
      if (status) {
        throw new Response(e.message, { status });
      }
    }

    throw e;
  }
};
