import { countries } from 'countries-list';
import sha256 from 'crypto-js/sha256';

export enum Locales {
  ES_AR = 'es-ar',
  EN_US = 'en-us',
  // PT_BR = 'pt-br',
}

export interface CodeAndName {
  code: string;
  name: string;
}

export interface LocaleInfo {
  country: CodeAndName;
  language: CodeAndName;
  flag: string;
}

export const SUPPORTED_LOCALES: Record<Locales, LocaleInfo> = {
  [Locales.ES_AR]: {
    country: {
      code: 'AR',
      name: 'Argentina',
    },
    language: {
      name: 'Español',
      code: 'ES',
    },
    flag: countries.AR.emoji,
  },
  [Locales.EN_US]: {
    country: {
      code: 'US',
      name: 'United States',
    },

    language: {
      code: 'EN',
      name: 'English',
    },
    flag: countries.US.emoji,
  },
  // [Locales.PT_BR]: {
  //   country: {
  //     code: 'BR',
  //     name: 'Brasil',
  //   },

  //   language: {
  //     code: 'PT',
  //     name: 'Português',
  //   },
  //   flag: countries.BR.emoji,
  // },
};

export const DEFAULT_LOCALE = Locales.ES_AR;
export const getBrowserLocale = (): string => navigator.language;
export const getBrowserTimezone = (): string => {
  return (Intl.DateTimeFormat().resolvedOptions() as { timeZone: string }).timeZone;
};

export const isSupportedLocale = (value: string): value is Locales => {
  return Object.values(Locales).includes(value.toLowerCase() as Locales);
};

export const guessLocale = (): Locales => {
  const BROWSER_LOCALE = getBrowserLocale();

  if (isSupportedLocale(BROWSER_LOCALE)) {
    return BROWSER_LOCALE.toLocaleLowerCase() as Locales;
  }

  const [language] = BROWSER_LOCALE.split('-');

  const similarLocal = guessLocaleByLang(language);

  if (similarLocal) {
    return similarLocal;
  }

  return DEFAULT_LOCALE;
};

export const guessCountry = (): string | null => {
  const BROWSER_LOCALE = getBrowserLocale();

  const [, countryCode] = BROWSER_LOCALE.split('-');

  return countryCode;
};

export const guessLocaleByLang = (language: string): Locales | null => {
  const found = Object.entries(SUPPORTED_LOCALES).find(([, localeInfo]) => {
    return localeInfo.language.code.toLowerCase() === (language ?? '').toLowerCase();
  });

  if (Array.isArray(found)) {
    return found[0] as Locales;
  }

  return null;
};

/**
 * This function is used to generate a fixed string id for improving performance
 * in translations storing , does not imply any security risk to keep the
 * hashing on the client side.
 * In case of collision we should ask the translator to make subtle changes in the translation
 *
 * @param text dynamic text
 * @returns
 */
export const generateMessageId = (text: string): string => {
  return sha256(text).toString();
};
