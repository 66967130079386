import React from 'react';
import { HStack, Icon, Text, Box, BoxProps, Flex } from '@chakra-ui/react';
import { RiCopyrightLine as CopyrightIcon } from 'react-icons/ri';
import { getEnvs } from '../../utils/getEnvs';
import { FaDev as DevIcon } from 'react-icons/fa';
import { TranslatedMessage } from '../../i18n';
import { commonDataViewMessages } from '../../i18n/commonMessages';

const { env, appVersion } = getEnvs();

export const Footer: React.FC<BoxProps> = (props) => {
  return (
    <Box px={[0, 2]} {...props}>
      <Flex
        bg="bg500"
        rounded={[0, 'md']}
        px={5}
        pt={'.5rem'}
        pb={'calc(env(safe-area-inset-bottom) + .5rem)'}
        borderBottomRadius={0}
        justifyContent={'space-between'}
        alignItems="center"
      >
        <HStack>
          {env !== 'production' && <Icon as={DevIcon} />}
          <TranslatedMessage message={commonDataViewMessages.v} values={{ version: appVersion }} />
        </HStack>
        <HStack justifyContent="center">
          <Icon as={CopyrightIcon} />
          <Text>{new Date().getFullYear()}</Text>
          <Text>Growcast</Text>
        </HStack>
      </Flex>
    </Box>
  );
};
