import React from 'react';
import { API, APIOutputs } from '../../../api/trpc';
import { AccordionProps, Flex, Grid, Skeleton, VStack } from '@chakra-ui/react';
import { APIError } from '../../Error/ErrorViewer';
import { TranslatedMessage } from '../../../i18n';
import { defineMessages } from 'react-intl';
import { PlansAccordion } from './PlansAccordion';

const messages = defineMessages({
  noPlansAvailable: {
    id: 'SubscribeToPlansAccordionProps.no_plans_available',
    defaultMessage: 'No hay planes disponibles en este momento',
  },
});

export type PlanListOutput = APIOutputs['subscriptions']['listPlans'];
export type Plan = PlanListOutput[number];

interface SubscribeToPlansAccordionProps extends AccordionProps {
  SubscriptionButton: React.FC<{ plan: Plan }>;
}
export const SubscribeToPlansAccordion: React.FC<SubscribeToPlansAccordionProps> = (props) => {
  const { SubscriptionButton, ...accordionProps } = props;

  const { data: plans, isLoading, error } = API.subscriptions.listPlans.useQuery();

  if (isLoading) {
    return <PlansAccordionSkeleton />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (plans === undefined || plans.length === 0) {
    return (
      <Flex my={6} justifyContent={'center'} alignItems={'center'}>
        <TranslatedMessage message={messages.noPlansAvailable} color="fontColorLight" />
      </Flex>
    );
  }

  return <PlansAccordion plans={plans} SubscriptionButton={SubscriptionButton} {...accordionProps} />;
};

const PlansAccordionSkeleton = () => {
  return (
    <Flex direction={'column'} py={2}>
      <Flex height={20} bg={'bg400'} borderRadius={'md'} px={4} py={2}>
        <VStack my={2} alignItems={'start'}>
          <Skeleton height={12} width={40} borderRadius={'md'} endColor={'bg500'} startColor={'bg100'} />
          <Skeleton height={8} width={52} borderRadius={'md'} endColor={'bg500'} startColor={'bg100'} />
        </VStack>
      </Flex>

      <Grid
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        gap={[4, 6]}
        mx={[1, 2]}
        px={[4, 8]}
        py={[3, 4]}
        bg={'bg600'}
        borderRadius={'md'}
      >
        <Skeleton height={80} endColor={'bg500'} startColor={'bg100'} borderRadius={'lg'} />

        <Skeleton height={80} endColor={'bg500'} startColor={'bg100'} borderRadius={'lg'} />
      </Grid>

      <Flex height={20} bg={'bg400'} borderRadius={'md'} px={4} py={2}>
        <VStack my={2} alignItems={'start'}>
          <Skeleton height={12} width={40} borderRadius={'md'} endColor={'bg500'} startColor={'bg100'} />
          <Skeleton height={8} width={52} borderRadius={'md'} endColor={'bg500'} startColor={'bg100'} />
        </VStack>
      </Flex>
    </Flex>
  );
};
