import { useSessionUser } from '../session/useSessionUser';
import { ColorMode, isValidColorMode, themeConfig } from './config';

export const useUserThemeConfig = () => {
  const user = useSessionUser();

  if (!user.preferredColorMode) {
    return {
      ...themeConfig,
      useSystemColorMode: true,
    };
  }

  return {
    ...themeConfig,
    initialColorMode: isValidColorMode(user.preferredColorMode) ? user.preferredColorMode : ColorMode.DARK,
    useSystemColorMode: user.preferredColorMode === ColorMode.SYSTEM,
  };
};
