import React from 'react';
import { StackProps, Button, Heading, Icon, VStack, AbsoluteCenter } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { TranslatedMessage } from '../../i18n';
import { commonActionMessages } from '../../i18n/commonMessages';
import { FaArrowLeftLong as BackIcon } from 'react-icons/fa6';
import { IconType } from 'react-icons';
import { errorMessages } from '../../components/Error/formatter';

export const NotFound: React.FC<StackProps> = (props) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <AbsoluteCenter>
      <VStack direction="column" alignItems={'center'} gap={3} {...props}>
        <Heading colorScheme="gray" size={'xl'}>
          404
        </Heading>
        <TranslatedMessage message={errorMessages.notFound} colorScheme="gray" textAlign={'center'} />
        <Button onClick={onClick} variant={'link'} alignItems={'center'} colorScheme="brand" gap={2}>
          <Icon as={BackIcon as IconType} />
          <TranslatedMessage message={commonActionMessages.back} />
        </Button>
      </VStack>
    </AbsoluteCenter>
  );
};
