import { LoaderFunctionArgs } from 'react-router-dom';
import { APIUtils, getHTTPCodeFromTRPCError } from '../../../api/trpc';
import { TRPCClientError } from '@trpc/client';

export const createFirmwareViewerLoader = (utils: APIUtils) => async (args: LoaderFunctionArgs) => {
  try {
    const id = Number(args.params.id);

    const data = await utils.backoffice.firmware.get.ensureData({ id });

    return data;
  } catch (e) {
    if (e instanceof TRPCClientError) {
      const status = getHTTPCodeFromTRPCError(e);
      if (status) {
        throw new Response(e.message, { status });
      }
    }

    throw e;
  }
};
