import { useState, useEffect } from 'react';
import { getEnvs } from '../utils/getEnvs';
import { DEFAULT_LOCALE } from './utils';

export function useTranslatedMessages(locale: string | null) {
  const language = (locale ?? DEFAULT_LOCALE).split('-')[0]?.toLocaleLowerCase();
  const { apiBaseURL } = getEnvs();

  const url = `${apiBaseURL}/lang/${language}.json`;

  const [messages, setMessages] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMessages = async () => {
      setError(null);

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Error fetching messages: ${response.statusText}`);
        }
        const data = (await response.json()) as Record<string, string>;

        setMessages(data);
      } catch (err) {
        // lets ignore it for now
        setError('could_not_load_translations');
      } finally {
        setLoading(false);
      }
    };

    void fetchMessages().catch(console.warn);
  }, [url]);

  return { messages, loading, error };
}
