import React from 'react';
import { Box, Container, Flex, Spacer } from '@chakra-ui/react';
import { Header } from './Header';
import { Navigate, Outlet, useNavigation } from 'react-router-dom';
import { Footer } from './Footer';
import { BottomToTopAnimation } from '..';
import { useSessionPermissions } from '../../session/useSessionPermissions';
import { Role } from 'common';
import { Scenes, sitemap } from '../../routing';
import { MQTTClientProvider } from '../../broker/MQTTClientContextProvider';
import { RealTimeListenerProvider } from '../../broker/RealTimeListenerContext';
import { JobExecutionProvider } from '../Jobs/JobExecutionContext';

export const UnauthTemplate: React.FC = () => {
  const { state } = useNavigation();

  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Container maxW={'lg'} px={{ base: 2, md: 4 }} flexGrow={1} alignContent={'center'}>
        <Box
          pointerEvents={state === 'loading' ? 'none' : undefined}
          opacity={state === 'loading' ? 0.5 : 1}
          transition="opacity 0.4s ease-out"
        >
          <Outlet />
        </Box>
      </Container>
      <BottomToTopAnimation>
        <Footer width={'full'} zIndex={'docked'} mt={10} />
      </BottomToTopAnimation>
    </Flex>
  );
};

export const SetupTemplate: React.FC = () => {
  const { state } = useNavigation();

  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Header isSidebarVisible />
      <Container maxW={'lg'} px={{ base: 2, md: 4 }}>
        {
          <Box
            pointerEvents={state === 'loading' ? 'none' : undefined}
            opacity={state === 'loading' ? 0.5 : 1}
            transition="opacity 0.4s ease-out"
          >
            <Outlet />
          </Box>
        }
      </Container>
      <Spacer />
      <BottomToTopAnimation>
        <Footer width={'full'} zIndex={'docked'} mt={10} />
      </BottomToTopAnimation>
    </Flex>
  );
};

export const FullAuthTemplate: React.FC = () => {
  const navigation = useNavigation();
  const { state } = navigation;

  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Header isSidebarVisible />
      <Container maxW={'container.xl'} px={{ base: 2, md: 4 }}>
        <MQTTClientProvider>
          <RealTimeListenerProvider>
            <JobExecutionProvider>
              {
                <Box
                  pointerEvents={state === 'loading' ? 'none' : undefined}
                  opacity={state === 'loading' ? 0.5 : 1}
                  transition="opacity 0.4s ease-out"
                >
                  <Outlet />
                </Box>
              }
            </JobExecutionProvider>
          </RealTimeListenerProvider>
        </MQTTClientProvider>
      </Container>

      <Spacer />

      <BottomToTopAnimation>
        <Footer width={'full'} zIndex={'docked'} mt={10} />
      </BottomToTopAnimation>
    </Flex>
  );
};

export const AdminTemplate: React.FC = () => {
  const permissions = useSessionPermissions();

  const isAdmin = permissions.hasRole(Role.OWNER);

  if (!isAdmin) {
    return <Navigate to={sitemap[Scenes.MY_CULTIVATION].path} />;
  }

  return <FullAuthTemplate />;
};

export const SmallAuthTemplate: React.FC = () => {
  const { state } = useNavigation();

  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Header isSidebarVisible />
      <Container maxW={'lg'} mx="auto" px={{ base: 2, md: 4 }}>
        <Box
          pointerEvents={state === 'loading' ? 'none' : undefined}
          opacity={state === 'loading' ? 0.5 : 1}
          transition="opacity 0.4s ease-out"
        >
          <Outlet />
        </Box>
      </Container>
      <Spacer />
      <BottomToTopAnimation>
        <Footer width={'full'} zIndex={'docked'} mt={10} />
      </BottomToTopAnimation>
    </Flex>
  );
};
