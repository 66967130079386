import React from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Icon,
  Image,
  HStack,
  Text,
  DrawerOverlay,
  Divider,
  VStack,
  Flex,
  Spacer,
  StackProps,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MessageDescriptor } from 'react-intl';
import Logo from '../../assets/logos/isotipo.png';
import { Scenes, sitemap } from '../../routing';
import { TranslatedMessage } from '../../i18n';
import {
  FaHardDrive as DeviceIcon,
  FaBuilding as OrganizationIcon,
  FaBuildingLock as OrganizationSettingsIcon,
  FaLock as AdminIcon,
  FaBookBookmark as CultivationRecordsIcon,
  FaHouzz as ZoneTypeIcon,
  FaUserGear as BackofficeIcon,
  FaUserCheck as ConfirmationIcon,
  FaRobot as AutomatizationsIcon,
  FaHeadset as SupportIcon,
  FaFlask as LabIcon,
} from 'react-icons/fa6';
import { MdFiberNew as NewIcon } from 'react-icons/md';
import { useSessionPermissions } from '../../session/useSessionPermissions';
import { Role } from 'common';
import { commonScenesMessages } from '../../i18n/commonMessages';
import { useSessionUser } from '../../session/useSessionUser';
import { SubscribeToPlanDrawerButton } from './SubscribeToPlanDrawerButton';
import { useMyOrganization } from '../Organizations/useMyOrganization';
import { getEnvs } from '../../utils/getEnvs';
import { IconType } from 'react-icons';
import { WhatsNew } from '../WhatsNew/WhatsNew';

interface AppDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>;
}

export const AppDrawer: React.FC<AppDrawerProps> = (props) => {
  const { buttonRef, isOpen, onClose } = props;

  const { release, appVersion } = getEnvs();

  const permissions = useSessionPermissions();
  const hasNoOrganizations = permissions.listOrganizations().length === 0;

  return (
    <Drawer
      finalFocusRef={buttonRef}
      placement={'left'}
      onClose={onClose}
      isOpen={isOpen}
      returnFocusOnClose={false}
      onOverlayClick={onClose}
    >
      <DrawerOverlay />
      <DrawerContent bg={'bg500'} pb={'env(safe-area-inset-bottom)'}>
        <DrawerHeader px={3} borderBottomWidth="1px" borderColor="borderColor">
          <VStack w={'100%'} spacing={4}>
            <Flex w={'100%'}>
              <HStack>
                <Image src={Logo} alt="logo" maxH={'42px'} />
                <Text>Growcast</Text>
              </HStack>
              <Spacer />
              <DrawerCloseButton top={0} right={0} h={'auto'} position={'relative'} />
            </Flex>
          </VStack>
        </DrawerHeader>
        <DrawerBody px={2} borderColor="borderColor">
          <Flex direction={'column'} height={'100%'}>
            {hasNoOrganizations ? <SetupSection /> : <RegularSection />}

            <Spacer />
            <WhatsNew />
            <GlobalOwnerSection />
            <Flex justifyContent="center" alignItems="center" mt={2}>
              <Text fontSize={'sm'} color="fontColorLight">
                {`${release} v${appVersion}`}
              </Text>
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const SetupSection: React.FC = () => {
  const { invite } = useSessionUser();

  if (invite !== undefined) {
    return (
      <DrawerItem navigateTo={sitemap[Scenes.SETUP].children.invite.path} label={commonScenesMessages.invite} icon={ConfirmationIcon} />
    );
  }

  return <DrawerItem navigateTo={sitemap[Scenes.SETUP].path} label={commonScenesMessages.setupDevice} icon={DeviceIcon} />;
};

const RegularSection: React.FC = () => {
  const { data: organization } = useMyOrganization();

  const hasSubscription = organization?.Subscription !== null;

  return (
    <Flex direction={'column'}>
      <DrawerItem navigateTo={sitemap[Scenes.MY_CULTIVATION].path} label={commonScenesMessages.myCultivation} icon={ZoneTypeIcon} />
      <DrawerItem navigateTo={sitemap[Scenes.DEVICES].path} label={commonScenesMessages.devices} icon={DeviceIcon} />
      <DrawerItem
        navigateTo={sitemap[Scenes.CULTIVATION_LOGS].path}
        label={commonScenesMessages.cultivationLogs}
        icon={CultivationRecordsIcon}
      />

      <DrawerItem navigateTo={sitemap[Scenes.AUTOMATIONS].path} label={commonScenesMessages.automations} icon={AutomatizationsIcon} />

      <DrawerItem navigateTo={sitemap[Scenes.LAB].path} label={commonScenesMessages.lab} icon={LabIcon} isNew />
      <DrawerDivider icon={OrganizationSettingsIcon} />

      <DrawerItem navigateTo={sitemap[Scenes.ORGANIZATION].path} label={commonScenesMessages.organization} icon={OrganizationIcon} />

      <DrawerItem navigateTo={sitemap[Scenes.SUPPORT].path} label={commonScenesMessages.support} icon={SupportIcon} isNew />

      <Spacer />

      {!hasSubscription && <SubscribeToPlanDrawerButton my={4} />}
    </Flex>
  );
};

const GlobalOwnerSection: React.VFC = () => {
  const permissions = useSessionPermissions();

  const isAdmin = permissions.hasRole(Role.OWNER);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <DrawerDivider icon={AdminIcon} />

      <DrawerItem navigateTo={sitemap[Scenes.BACKOFFICE].path} label={commonScenesMessages.backoffice} icon={BackofficeIcon} />
    </>
  );
};

interface DrawerItemProps {
  navigateTo: string;
  label: MessageDescriptor;
  icon?: IconType;
  isNew?: boolean;
}

export const DrawerItem: React.FC<DrawerItemProps> = (props) => {
  const { navigateTo, label, icon, isNew } = props;
  const navigate = useNavigate();

  const onClick = () => {
    navigate(navigateTo);
  };

  return (
    <Button size="lg" gap={2} onClick={onClick} variant={'ghost'} w={'full'} justifyContent={'start'} leftIcon={<Icon as={icon} />}>
      <Flex w="full">
        <TranslatedMessage message={label} />
        <Spacer />
        {isNew && <Icon as={NewIcon} fontSize={'xl'} color="fontColorLight" />}
      </Flex>
    </Button>
  );
};

interface DrawerDividerProps extends StackProps {
  icon: IconType;
}

const DrawerDivider: React.FC<DrawerDividerProps> = (props) => {
  const { icon, ...otherProps } = props;

  return (
    <HStack alignItems={'center'} px={5} my={4} spacing={4} {...otherProps}>
      <Icon as={icon} color={'fontColorLight'} fontSize={'.8rem'} />
      <Divider borderColor={'borderColor'} />
    </HStack>
  );
};
