import React from 'react';
import { MQTT5Client } from './client';
import { useLazyRef } from '../utils/useLazyRef';
import { mqtt5 } from 'aws-iot-device-sdk-v2/dist/browser';
import { defineMessages } from 'react-intl';
import { Alert, Button } from '@chakra-ui/react';
import { TranslatedMessage } from '../i18n';
import createDebugger from 'debug';
import { useDebouncedValue } from '../utils/useDebouncedValue';
import { AuthenticatedSession } from '../session/session';
import { useSession } from '../session/useSession';
import { useSessionUser } from '../session/useSessionUser';
import { MQTTClientContext } from './MQTTClientContext';

const debug = createDebugger('Growcast:MQTTClientProvider');

const messages = defineMessages({
  disconnectedError: {
    id: 'MQTTClient.disconnected_from_broker',
    defaultMessage: 'El servicio de tiempo real esta desconectado',
  },
  reconnect: {
    id: 'UI.button_reconnect',
    defaultMessage: 'Re-conectar ({seconds})',
  },
});

/**
 * it looked well but i started to think is not useful
 * i mean, anyone wants to be disconnected.
 */
const IS_DISCONNECTION_BANNER_VISIBLE = false;

export type MessageSubscriber = (eventData: mqtt5.MessageReceivedEvent) => void;
export type Unsubscriber = () => void;

type ConnectedClient = {
  isConnected: true;
  subscribe: (topic: string | Array<string>, cb: MessageSubscriber) => Promise<Unsubscriber | null>;
};

type DisconnectedClient = {
  isConnected: false;
  subscribe: null;
};

export type MQTTConnection = ConnectedClient | DisconnectedClient;

interface Props {
  children: React.ReactNode;
}

export const MQTTClientProvider: React.FC<Props> = (props) => {
  const { session, refresh } = useSession<AuthenticatedSession>();
  const { username } = useSessionUser();

  const [isConnecting, setIsConnecting] = React.useState(true);
  const [isConnected, setIsConnected] = React.useState(false);

  // to avoid blinking alert on first render in some browsers
  const debouncedDisconnectedStatus = useDebouncedValue(!isConnected && !isConnecting, 10000);

  const [client, setClient] = React.useState<mqtt5.Mqtt5Client | null>(null);
  const [subscribers, setSubscribers] = React.useState(new Map<string, MessageSubscriber>());

  const connection = useLazyRef(() => new MQTT5Client(username, session, refresh));
  const retryCount = React.useRef(0);

  const connect = React.useCallback(() => {
    if (retryCount?.current >= 3) {
      // something may be happening, lets refresh
      window.location.reload();
    } else {
      // retry
      retryCount.current = retryCount?.current + 1;

      setIsConnecting(true);

      connection
        .connect()
        .then(() => {
          debug('connected');
          retryCount.current = 0;
          setIsConnected(true);
          setClient(connection.client);

          const callback = () => {
            setIsConnected(false);

            debug('Disconnected');
          };

          connection?.onDisconnect(callback);
        })
        .catch((e) => {
          debug('error connecting: ', e);
          setIsConnected(false);
          setClient(null);
        })
        .finally(() => setIsConnecting(false));
    }
  }, [connection]);

  // subscribe to message method with unsubscre option
  const subscribe = React.useCallback(
    async (topicFilter: string | Array<string>, cb: MessageSubscriber) => {
      if (!client) {
        return null;
      }

      const topics = Array.isArray(topicFilter) ? topicFilter : [topicFilter];

      const subscriptions = topics.map((topic) => ({
        qos: mqtt5.QoS.AtMostOnce,
        topicFilter: topic,
      }));

      const suback = await client.subscribe({
        subscriptions,
      });

      if (suback.reasonCodes.some((reason) => reason !== 0)) {
        debug(suback.reasonCodes.join('\n'));
      }

      setSubscribers((prevSubscribers) => {
        const newSubscribers = new Map(prevSubscribers);

        if (Array.isArray(topicFilter)) {
          topicFilter.forEach((topic) => newSubscribers.set(topic, cb));
        } else {
          newSubscribers.set(topicFilter, cb);
        }

        debug({ newSubscribers });
        return newSubscribers;
      });

      // unsubscribe
      return () => {
        setSubscribers((prevSubscribers) => {
          const newSubscribers = new Map(prevSubscribers);

          if (Array.isArray(topicFilter)) {
            topicFilter.forEach((topic) => newSubscribers.delete(topic));
          } else {
            newSubscribers.delete(topicFilter);
          }
          return newSubscribers;
        });
      };
    },
    [client]
  );

  // connection
  React.useEffect(() => {
    debug('first connection');

    connect();

    return () => {
      void connection?.disconnect().catch((error) => debug('error disconnecting', error));
    };
  }, [connection, connect]);

  //subscriptions
  React.useEffect(() => {
    const handler = (eventData: mqtt5.MessageReceivedEvent) => {
      // find corresponding callback
      const subscribedTopic = Array.from(subscribers.keys()).find((topic) => {
        // replace wildcards
        const regexString = topic
          .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') // Escape special characters
          .replace(/\\\+/g, '([^/]+)') // Convert '+' to a single-level wildcard match
          .replace(/\\#/g, '(.*)'); // Convert '#' to a multi-level wildcard match

        const regex = new RegExp(`^${regexString}$`);
        const match = regex.test(eventData.message.topicName);

        return !!match;
      });

      const callback = subscribers.get(subscribedTopic ?? '');

      if (callback) {
        callback(eventData);
      }
    };

    client?.on('messageReceived', handler);

    return () => {
      client?.off('messageReceived', handler);
    };
  }, [client, subscribers]);

  debug({ isConnected, isConnecting, debouncedDisconnectedStatus });

  // return context value
  const value: MQTTConnection =
    isConnected && client !== null
      ? {
          isConnected: true,
          subscribe,
        }
      : {
          isConnected: false,
          subscribe: null,
        };

  const isReconnectionVisible = !isConnecting && !isConnected && debouncedDisconnectedStatus;
  return (
    <MQTTClientContext.Provider value={value}>
      {props.children}
      {/* I want it to debounce the disconnextion but hide it immediately is reconnecting */}
      {isReconnectionVisible && <Reconnection reconnect={connect} />}
    </MQTTClientContext.Provider>
  );
};

interface ReconnectButtonProps {
  reconnect: () => void;
}

const RECONECTION_DELAY = 3000;
const Reconnection: React.FC<ReconnectButtonProps> = (props) => {
  const { reconnect } = props;

  const [seconds, setSeconds] = React.useState(RECONECTION_DELAY);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1000);
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    if (seconds <= 0) {
      reconnect();
      setSeconds(RECONECTION_DELAY);
    }
  }, [seconds, reconnect]);

  //  templorary hide it
  if (!IS_DISCONNECTION_BANNER_VISIBLE) {
    return null;
  }

  return (
    <Alert justifyContent={'space-between'} variant="solid" status={'error'} position={'fixed'} left={0} bottom={0} zIndex={'modal'}>
      <TranslatedMessage message={messages.disconnectedError} />

      <Button onClick={reconnect} color="black">
        <TranslatedMessage message={messages.reconnect} values={{ seconds: seconds / 1000 }} />
      </Button>
    </Alert>
  );
};
