import { APIUtils, getHTTPCodeFromTRPCError } from '../../api/trpc';
import { TRPCClientError } from '@trpc/client';

export const createSubscriptionViewerLoader = (utils: APIUtils) => async () => {
  try {
    const data = await utils.subscriptions.getMine.ensureData();

    return data;
  } catch (e) {
    if (e instanceof TRPCClientError) {
      const status = getHTTPCodeFromTRPCError(e);
      if (status) {
        throw new Response(e.message, { status });
      }
    }

    throw e;
  }
};
