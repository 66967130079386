import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Stepper,
  Step,
  StepIndicator,
  Box,
  useDisclosure,
  UnorderedList,
  OrderedList,
  ListItem,
  Link,
  Flex,
  ModalCloseButton,
  Spacer,
  HStack,
  Heading,
  Text,
  AbsoluteCenter,
} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import { defineMessages } from 'react-intl';
import { TranslatedMessage } from '../../i18n';
import { commonActionMessages } from '../../i18n/commonMessages';
import { ChangeLogVersionSelector } from '../Form/Selectors/ChangeLogVersionSelector';
import { API } from '../../api/trpc';
import { APIError } from '../Error/ErrorViewer';
import { Loading } from '../Loading';
import { FaFire as NewsIcon } from 'react-icons/fa6';

const messages = defineMessages({
  whatsNewTitle: {
    id: 'WhatsNew.title',
    defaultMessage: 'Novedades',
  },
  whatsNewButton: {
    id: 'WhatsNew.button_label',
    defaultMessage: 'Descubre lo nuevo',
  },
});

export const WhatsNew: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedVersion, setSelectedVersion] = React.useState<null | string>(null);

  const {
    data: exampleChangeLogs = [],
    isLoading,
    error,
  } = API.changeLogs.list.useQuery(
    {
      version: Number(selectedVersion),
    },
    {
      enabled: !isNaN(Number(selectedVersion)) && isOpen,
    }
  );

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    onClose();
    setActiveStep(0);
  };

  const onVersionChange = (newVersion: string | number) => {
    setSelectedVersion(String(newVersion));
    setActiveStep(0);
  };

  const isNextDisabled = activeStep === exampleChangeLogs.length - 1;
  const isBackDisabled = activeStep === 0;

  return (
    <Box>
      <Button onClick={onOpen} w="full" colorScheme="purple" variant="outline" leftIcon={<NewsIcon />}>
        <TranslatedMessage message={messages.whatsNewButton} />
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Flex gap={2} direction={'column'} minH="xs">
              {isLoading ? (
                <AbsoluteCenter>
                  <Loading colorScheme="brand" size="lg" />
                </AbsoluteCenter>
              ) : (
                <Box maxW={'full'}>
                  <ReactMarkdown
                    components={{
                      ul: ({ children }) => (
                        <UnorderedList spacing={2} px={1}>
                          {children}
                        </UnorderedList>
                      ),
                      ol: ({ children }) => (
                        <OrderedList spacing={2} px={1}>
                          {children}
                        </OrderedList>
                      ),
                      li: ({ children }) => <ListItem px={1}>{children}</ListItem>,
                      p: ({ children }) => <Text my={2}>{children}</Text>,

                      a: ({ href, children }) => (
                        <Link href={href} color="brand.500" isExternal>
                          {children}
                        </Link>
                      ),
                      h1: ({ children }) => (
                        <Heading as="h1" size="2xl" my={4}>
                          {children}
                        </Heading>
                      ),
                      h2: ({ children }) => (
                        <Heading as="h2" size="xl" my={4}>
                          {children}
                        </Heading>
                      ),
                      h3: ({ children }) => (
                        <Heading as="h3" size="lg" my={4}>
                          {children}
                        </Heading>
                      ),
                      h4: ({ children }) => (
                        <Heading as="h4" size="md" my={4}>
                          {children}
                        </Heading>
                      ),
                      h5: ({ children }) => (
                        <Heading as="h5" size="sm" my={4}>
                          {children}
                        </Heading>
                      ),
                      h6: ({ children }) => (
                        <Heading as="h6" size="xs" my={4}>
                          {children}
                        </Heading>
                      ),
                    }}
                  >
                    {exampleChangeLogs[activeStep]?.description}
                  </ReactMarkdown>
                </Box>
              )}
              <Spacer />
              <Flex justifyContent="center">
                <Stepper index={activeStep} orientation="horizontal" size="xs" gap={2}>
                  {exampleChangeLogs.map((_, index) => (
                    <Step key={index}>
                      <StepIndicator
                        width={'10px'}
                        height={'10px'}
                        sx={{
                          '[data-status=complete] &': {
                            background: 'brand.400',
                            borderColor: 'brand.400',
                          },
                          '[data-status=active] &': {
                            background: 'brand.400',
                            borderColor: 'whiteAlpha.800',
                          },
                          '[data-status=incomplete] &': {
                            background: 'transparent',
                            borderColor: 'brand.400',
                          },
                        }}
                      />
                    </Step>
                  ))}
                </Stepper>
              </Flex>
            </Flex>
            {error && <APIError error={error} />}
          </ModalBody>
          <ModalFooter justifyContent={'space-between'}>
            <ChangeLogVersionSelector
              value={selectedVersion}
              onChange={onVersionChange}
              isMulti={false}
              containerProps={{ minW: 24 }}
              autoSelectLatest
            />

            <HStack spacing={3}>
              <Button onClick={handleBack} isDisabled={isBackDisabled}>
                <TranslatedMessage message={commonActionMessages.back} />
              </Button>
              <Button onClick={handleNext} isDisabled={isNextDisabled}>
                <TranslatedMessage message={commonActionMessages.next} />
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
