import { assertUnreachable } from './validations';
import timezones from 'timezones-list';
import { getTimezoneOffset } from 'date-fns-tz';

/**
 * This file contains interfaces and util fn to manage dates,
 * like fixed periods definitions for histories and parser
 */
export enum HistoryPeriod {
  last24hours = 'LAST_24_HOURS',
  last3Days = 'LAST_3_DAYS',
  lastWeek = 'LAST_WEEK',
  last2Weeks = 'LAST_2_WEEKS',
  lastMonth = 'LAST_MONTH',
  last3Months = 'LAST_3_MONTHS',
  last6Months = 'LAST_6_MONTHS',
  lastYear = 'LAST_YEAR',
}

export function parsePeriodToDate(period: HistoryPeriod): Date {
  const from = new Date();

  switch (period) {
    case HistoryPeriod.last24hours:
      from.setDate(new Date().getDate() - 1);
      break;
    case HistoryPeriod.last3Days:
      from.setDate(new Date().getDate() - 3);
      break;
    case HistoryPeriod.lastWeek:
      from.setDate(new Date().getDate() - 7);
      break;
    case HistoryPeriod.last2Weeks:
      from.setDate(new Date().getDate() - 14);
      break;
    case HistoryPeriod.lastMonth:
      from.setMonth(new Date().getMonth() - 1);
      break;
    case HistoryPeriod.last3Months:
      from.setMonth(new Date().getMonth() - 3);
      break;
    case HistoryPeriod.last6Months:
      from.setMonth(new Date().getMonth() - 6);
      break;
    case HistoryPeriod.lastYear:
      from.setFullYear(new Date().getFullYear() - 1);
      break;
    default:
      assertUnreachable('invalid period', period);
  }

  return from;
}
interface TimeParts {
  hours: number;
  minutes: number;
  seconds: number;
}
export const extractTimeParts = (dateString: string | Date): null | TimeParts => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    null;
  }

  return {
    hours: date.getUTCHours(),
    minutes: date.getUTCMinutes(),
    seconds: date.getUTCSeconds(),
  };
};

export enum DaysOfWeek {
  SUNDAY = '0',
  MONDAY = '1',
  TUESDAY = '2',
  WEDNESDAY = '3',
  THURSDAY = '4',
  FRIDAY = '5',
  SATURDAY = '6',
}

/**
 *  Function to list all timezones
 */
export function getTimezones() {
  return timezones;
}

/**
 * get timezone offset in seconds for devices
 */

export const getTimezoneOffsetInSeconds = (timezone: string) => {
  const offsetInMilliSeconds = getTimezoneOffset(timezone);

  return Math.round(offsetInMilliSeconds / 1000);
};

export const getStartOfDay = (date: Date): Date => {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
};

export const getEndOfDay = (date: Date): Date => {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
};

export const isStartOfDay = (date: Date): boolean => {
  return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
};

export const isEndOfDay = (date: Date): boolean => {
  return date.getHours() === 23 && date.getMinutes() === 59 && date.getSeconds() === 59;
};

export const formatTimeZone = (timezone: string, utc?: string) => {
  const zonePart = timezone.split('/').join(', ').replace(/_/g, ' ');

  if (utc) {
    return `${zonePart} (${utc})`;
  }

  return zonePart;
};

/**
 * This function checks if a date is older than nMonthsAgo
 * @param date
 * @param nMonths
 * @param timezone
 * @returns boolean
 */
export const isOlderThanNMonthsAgo = (date: Date, nMonths: number, timezone?: string): boolean => {
  const timeZoneOffsetInSeconds = timezone ? getTimezoneOffsetInSeconds(timezone) : 0;

  const value = new Date(date);
  value.setHours(0, 0, 0, 0);

  const nMonthsAgo = new Date();
  nMonthsAgo.setMonth(nMonthsAgo.getMonth() - nMonths);
  nMonthsAgo.setSeconds(nMonthsAgo.getSeconds() - timeZoneOffsetInSeconds);
  nMonthsAgo.setHours(0, 0, 0, 0);

  return value < nMonthsAgo;
};
/*
 * Convert a frequency object to ms to apply as offset to a date
 */

export enum DurationFrequencyType {
  MONTHS = 'months',
  DAYS = 'days',
}

export const isValidDurationFrequencyType = (type: string): type is DurationFrequencyType => {
  return Object.values(DurationFrequencyType).includes(type as DurationFrequencyType);
};

export interface Duration {
  frequency: number;
  frequencyType: DurationFrequencyType;
}

export const addDurationToDate = (duration: Duration, date: Date): Date => {
  const newDate = new Date(date);

  switch (duration.frequencyType) {
    case DurationFrequencyType.MONTHS:
      newDate.setMonth(newDate.getMonth() + duration.frequency);
      break;
    case DurationFrequencyType.DAYS:
      newDate.setDate(newDate.getDate() + duration.frequency);
      break;
    default:
      assertUnreachable('Invalid frequency type', duration.frequencyType);
  }

  return newDate;
};

export const addDaysToDate = (days: number, date: string): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);

  return newDate;
};

export const isSameDay = (date1: string, date2: string): boolean => {
  const day1 = new Date(date1).getDate();
  const day2 = new Date(date2).getDate();
  return day1 === day2;
};

interface Progress {
  totalDurationInDays: number;
  percentageProgress: number;
  pendingDays: number;
}
export const calculateProgress = (startDate: Date, duration: number, today: Date): Progress => {
  const millisInOneDay = 1000 * 60 * 60 * 24;

  if (duration === 0) {
    return {
      totalDurationInDays: 0,
      percentageProgress: 100,
      pendingDays: 0,
    };
  }

  const startTime = new Date(startDate).getTime();
  const todayTime = new Date(today).getTime();

  const elapsedDuration = todayTime - startTime;
  const elapsedDurationInDays = Math.round(elapsedDuration / millisInOneDay);

  const totalDurationInDays = Math.round(duration / millisInOneDay);
  const pendingDays = totalDurationInDays - elapsedDurationInDays;

  const percentageProgress =
    elapsedDuration < 0
      ? 0 // Negative elapsed time
      : Math.min((elapsedDuration / duration) * 100, 100);

  return {
    totalDurationInDays,
    percentageProgress,
    pendingDays,
  };
};
