import React from 'react';
import ReactMarkdown from 'react-markdown';
import { List, ListItem, OrderedList, UnorderedList, Link, Heading, Text, Image, Icon, ListIcon } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { FaCheck as ListItemIcon, FaLink as LinkIcon } from 'react-icons/fa6';

interface Props {
  content: string;
  prettyList?: boolean;
}
export const MarkdownViewer: React.FC<Props> = (props) => {
  return (
    <ReactMarkdown
      components={{
        ul: ({ children }) =>
          props.prettyList ? (
            <List spacing={1} fontSize={'sm'}>
              {children}
            </List>
          ) : (
            <UnorderedList>{children}</UnorderedList>
          ),
        ol: ({ children }) =>
          props.prettyList ? (
            <List spacing={1} fontSize={'sm'}>
              {children}
            </List>
          ) : (
            <OrderedList>{children}</OrderedList>
          ),
        li: ({ children }) =>
          props.prettyList ? (
            <ListItem textAlign={'left'} fontSize={'sm'}>
              <ListIcon as={ListItemIcon} color={'subscriptionHover'} />
              {children}
            </ListItem>
          ) : (
            <ListItem>{children}</ListItem>
          ),
        p: ({ children }) => <Text my={2}>{children}</Text>,
        a: ({ href, children }) => {
          const isAbsoluteUrl = (url: string) => {
            const pattern = new RegExp('^(https?:|mailto:|tel:)', 'i');
            return pattern.test(url);
          };

          if (href && isAbsoluteUrl(href)) {
            return (
              <Link href={href} isExternal whiteSpace="nowrap">
                <Icon as={LinkIcon} mr={1} mb={'-2px'} />
                {children}
              </Link>
            );
          }

          return (
            <Link as={ReactRouterLink} to={href} whiteSpace="nowrap">
              <Icon as={LinkIcon} mr={1} mb={'-2px'} />
              {children}
            </Link>
          );
        },
        h1: ({ children }) => (
          <Heading as="h1" size="2xl" my={4}>
            {children}
          </Heading>
        ),
        h2: ({ children }) => (
          <Heading as="h2" size="xl" my={4}>
            {children}
          </Heading>
        ),
        h3: ({ children }) => (
          <Heading as="h3" size="lg" my={4}>
            {children}
          </Heading>
        ),
        h4: ({ children }) => (
          <Heading as="h4" size="md" my={4}>
            {children}
          </Heading>
        ),
        h5: ({ children }) => (
          <Heading as="h5" size="sm" my={4}>
            {children}
          </Heading>
        ),
        h6: ({ children }) => (
          <Heading as="h6" size="xs" my={4}>
            {children}
          </Heading>
        ),
        img: ({ src, alt }) => <Image src={src} alt={alt} maxW={[48, 64, 'lg', 'xl']} maxH={[64, 80, 'lg', 'xl']} objectFit={'cover'} />,
      }}
    >
      {props.content}
    </ReactMarkdown>
  );
};
