import React from 'react';

/**
 * useAutoHidden is a custom React hook that provides functionality to hide or show a header based on the user's scroll direction.
 *
 * The hook returns a `ref` to be attached to the header element and a `y` value that determines the header's vertical position.
 *
 * The header will hide when the user scrolls down and show when the user scrolls up.
 *
 * The hook works as follows:
 * - It keeps track of the last scroll position and the last header position using refs.
 * - On each scroll event, it calculates the difference between the current scroll position and the last scroll position.
 * - If the scroll direction is the same as the last scroll direction, it adds the scroll difference to the header's position.
 * - If the scroll direction changes, it starts moving the header in the opposite direction.
 * - The `y` value is calculated based on the header's height, the header's position, and the current scroll position.
 * - The `ref` is used to get the header's height when the component mounts.
 *
 * This hook is useful for creating a sticky header that hides when scrolling down and shows when scrolling up, improving the user experience by saving screen space.
 */

export const useAutoHidden = () => {
  const lastHeaderYRef = React.useRef(0);
  const lastScrollYRef = React.useRef(0);

  const ref = React.useRef<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = React.useState(48);
  const [headerY, setHeaderY] = React.useState(0);

  React.useEffect(() => {
    if (ref.current) {
      setHeaderHeight(ref.current.offsetHeight);
    }
  }, []);

  const handleScroll = React.useCallback(() => {
    const currentScrollY = window.scrollY;

    // this determinate the direction
    const scrollDifference = currentScrollY - lastScrollYRef.current;

    // same direction will add more Y to the header.
    if (isSameSign(scrollDifference, lastHeaderYRef.current)) {
      const newValue = lastHeaderYRef.current + scrollDifference;

      setHeaderY(newValue);
      lastHeaderYRef.current = newValue;
    } else {
      // if direction changed, start moving header in oposite direction
      lastHeaderYRef.current = scrollDifference;
      setHeaderY(scrollDifference);
    }

    lastScrollYRef.current = currentScrollY;
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const y = calculateActualHeaderY(headerHeight, headerY, lastScrollYRef.current);

  return {
    y,
    ref,
  };
};

const isSameSign = (num1: number, num2: number): boolean => {
  return (num1 >= 0 && num2 >= 0) || (num1 < 0 && num2 < 0);
};

/**
 * This function calculates the actual Y position of the header based on its height and the scroll displacement.
 * If the displacement is positive, it returns the minimum between the displacement and the header height.
 * If the displacement is negative, it returns 0 when the absolute displacement is less than or equal to the height.
 * Essentially, it is used as the negative of the translate value.
 * When scrolling down, the output displacement should be -displacement but with a maximum value of -height, so it remains hidden with -height.
 * When scrolling back up, it should start to show, reaching a maximum of 0 so the entire header is visible.
 *
 * @param {number} headerHeight - The height of the header.
 * @param {number} headerY - The scroll displacement.
 * @returns {number} - The actual Y position of the header.
 */
const calculateActualHeaderY = (headerHeight: number, headerY: number, scroll: number): number => {
  if (scroll === 0) {
    return 0;
  }

  if (headerY > 0) {
    return -1 * Math.min(headerY, headerHeight);
  } else {
    return Math.abs(headerY) >= headerHeight ? 0 : -1 * (headerY + headerHeight);
  }
};
