import React from 'react';
import { IntlProvider } from 'react-intl';
import { getBrowserLocale, DEFAULT_LOCALE, Locales, isSupportedLocale } from './utils';
import { useSessionUser } from '../session/useSessionUser';
import { enUS, es } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { assertUnreachable } from 'common';
import createDebugger from 'debug';
import { useTranslatedMessages } from './useTranslatedMessages';

const debug = createDebugger('I18N:TranslationProvider');
interface Props {
  children?: React.ReactNode;
}

const useLocale = () => {
  try {
    const user = useSessionUser();

    return user.locale;
  } catch (e) {
    return null;
  }
};

export const TranslationProvider: React.FC<Props> = (props) => {
  const BROWSER_LOCALE = getBrowserLocale();
  const locale = useLocale();

  const { messages } = useTranslatedMessages(locale);

  React.useEffect(() => {
    if (locale !== null && isSupportedLocale(locale)) {
      switch (locale) {
        case Locales.EN_US:
          registerLocale(locale, enUS); // eslint-disable-line
          break;

        case Locales.ES_AR:
          registerLocale(locale, es); // eslint-disable-line
          break;

        default:
          assertUnreachable('Invalid locale', locale);
      }
    }

    /**
     * This is an experiment to restrict the browser to auto translate between
     * different locales with the same lang. Right now, users with automatic
     * translation enable and other 'es' locales apart from es-AR get some messages
     * replaced with different es translations
     */
    const finalLocale = locale ?? BROWSER_LOCALE;
    document.documentElement.lang = finalLocale.substring(0, 2);
  }, [locale, BROWSER_LOCALE]);

  return (
    <IntlProvider
      locale={locale ?? BROWSER_LOCALE}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages}
      onError={(e) => {
        debug(e);
      }}
    >
      {props.children}
    </IntlProvider>
  );
};
