import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Scenes, sitemap } from '../sitemap';
import { SetupTemplate } from '../../components/Layout/Template';
import { RouterErrorHandler } from '../error/RouterErrorHandler';
import { NavigateInvite, NavigteWithQueryParams } from '../utils';
import { SetupFallbackElement } from './FallbackElement';
import { Header } from '../../components/Layout/Header';
import { Flex, Spacer } from '@chakra-ui/react';
import { Footer } from '../../components/Layout/Footer';

const router = createBrowserRouter([
  {
    path: sitemap[Scenes.SETUP].path,
    element: <SetupTemplate />,
    errorElement: (
      <Flex direction="column" minH="100dvh" bg={'appBg'}>
        <Header isSidebarVisible />
        <RouterErrorHandler />
        <Spacer />
        <Footer width={'full'} zIndex={'docked'} mt={2} />
      </Flex>
    ),
    children: [
      {
        index: true,
        lazy: () => import('../../scenes/Setup/Index'),
      },
      {
        path: sitemap[Scenes.SETUP].children.invite.path,
        lazy: () => import('../../scenes/Setup/Invite'),
      },
      {
        path: '*',
        element: <NavigteWithQueryParams to={sitemap[Scenes.SETUP].path} />,
      },
    ],
  },
  {
    path: sitemap[Scenes.PROFILE].path,
    element: <SetupTemplate />,
    children: [
      {
        index: true,
        lazy: () => import('../../scenes/Profile/Index'),
      },
      {
        path: sitemap[Scenes.PROFILE].children.emailVerification.path,
        lazy: () => import('../../scenes/Profile/AttributeVerification'),
      },
      {
        path: sitemap[Scenes.PROFILE].children.notifications.path,
        lazy: () => import('../../scenes/Profile/Notifications'),
      },
      {
        path: sitemap[Scenes.PROFILE].children.phoneVerification.path,
        lazy: () => import('../../scenes/Profile/AttributeVerification'),
      },
      {
        path: sitemap[Scenes.PROFILE].children.preferences.path,
        lazy: () => import('../../scenes/Profile/Preferences'),
      },
      {
        path: sitemap[Scenes.PROFILE].children.security.path,
        lazy: () => import('../../scenes/Profile/Security'),
      },
      {
        path: '*',
        element: <NavigteWithQueryParams to={sitemap[Scenes.PROFILE].path} />,
      },
    ],
  },
  {
    path: '*',
    element: <NavigateInvite />,
  },
]);

export const SetupRouter: React.FC = () => {
  return <RouterProvider router={router} fallbackElement={<SetupFallbackElement />} />;
};
