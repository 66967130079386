import { AbsoluteCenter, Card, CardBody, CardHeader, Container, Flex, Skeleton, VStack } from '@chakra-ui/react';
import { Loading } from '../../components';

export const RootAuthFallbackElement = () => {
  return (
    <Flex direction="column" minH="100vh" bg={'appBg'} gap={2}>
      <AbsoluteCenter>
        <Loading size={'lg'} />
      </AbsoluteCenter>
    </Flex>
  );
};

export const SetupFallbackElement = () => {
  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Container maxW={'lg'} py={4} px={0}>
        <Flex direction={'column'} gap={2}>
          <Card>
            <CardHeader>
              <Skeleton width={'200px'} height={12} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
            </CardHeader>
            <CardBody>
              <VStack spacing={12} alignItems={'start'} width={'100%'}>
                <Flex direction={'column'} width={'100%'} gap={4}>
                  <Skeleton width={'100%'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                  <Skeleton width={'100%'} height={16} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                  <Skeleton width={'100%'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                </Flex>
                <Flex direction={'row'} width={'100%'} justifyContent={'space-between'} gap={4} my={4}>
                  <Skeleton width={'100px'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                  <Skeleton width={'100px'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                </Flex>
              </VStack>
            </CardBody>
          </Card>
        </Flex>
      </Container>
    </Flex>
  );
};
