import React from 'react';
import { ChakraProvider, extendTheme, Theme, useColorMode, useTheme, ColorModeScript } from '@chakra-ui/react';
import { useSession } from '../session/useSession';
import { useUserThemeConfig } from './useThemeConfig';
import { ColorMode, themeConfig } from '.';
import { ThemeConfig } from '@chakra-ui/react';

interface Props {
  children?: React.ReactNode;
}

export type UserTheme = ReturnType<typeof useUserThemeConfig> & ThemeConfig;
export type AppTheme = Theme & UserTheme;

const AuthThemeProvider: React.FC<Props> = (props) => {
  const userConfig = useUserThemeConfig();
  const theme = extendTheme(userConfig);

  return (
    <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'bottom-right' } }}>
      <ColorModeScript initialColorMode={userConfig.initialColorMode as ColorMode} />
      {props.children}
      <SyncColorMode />
    </ChakraProvider>
  );
};

export const ThemeProvider: React.FC<Props> = (props) => {
  const { isLoggedIn } = useSession();

  if (isLoggedIn) {
    return <AuthThemeProvider>{props.children}</AuthThemeProvider>;
  }

  const theme = extendTheme(themeConfig);
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.initialColorMode as ColorMode} />
      <SyncColorMode />
      {props.children}
    </ChakraProvider>
  );
};

/**
 * This is the only way I found to sync correctly the color mode
 * with the actual value in session. if no preference, it takes the one from the system
 */
const SyncColorMode: React.FC = () => {
  const { colorMode } = useColorMode();

  const theme = useTheme<AppTheme>();

  React.useEffect(() => {
    const appBgHex = colorMode === ColorMode.DARK ? theme.colors.dark[900] : theme.colors.light[800];
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');

    if (metaThemeColor !== null) {
      metaThemeColor.setAttribute('content', appBgHex);
    }
  }, [theme, colorMode]);

  return null;
};
